import React, { useState } from 'react';
import { resetPassword } from '../services/supabaseClient';
import AuthContainer from '../components/AuthContainer'; // Adjust the path as needed
import FormInput from '../components/FormInput';
import './ForgotPasswordPage.css'; // Your specific styles for the registration form
import FormButton from '../components/FormButton';
import logo from '../assets/logo.png';


const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');

    try {
        const { error } = await resetPassword(email);
        if (error) {
            setError(error.message);
        } else {
            setMessage('Password reset email sent. Please check your inbox.');
        }
    } catch (error) {
        setError('An unexpected error occurred.');
    }
};

return (
    <div>
        <img src={logo} alt="Company Logo" className="company-logo" />
        <AuthContainer title="Reset password">
            <p>Enter your email to receive reset instructions.</p>
            {message && <p className="message">{message}</p>}
            {error && <p className="error">{error}</p>}
            <form onSubmit={handleSubmit}>
                <FormInput
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <FormButton label="Reset Password" type="submit" />
            </form>
            <p>
                Return to <a href="/login">Log in</a>
            </p>
        </AuthContainer>
    </div>
  );
  };
  
  export default ForgotPasswordPage;