import React from 'react';
// import ProgressTracker from '../ProgressTracker';
import './OnboardingLayout.css'; // Import the styles for the layout
import logo from '../../assets/logo.png'

const OnboardingLayout = ({ currentStep, children }) => {
  return (
    <div className="onboarding-layout">
        <img src={logo} alt="Company Logo" className="company-logo" />
        {children} {/* This is where the content for each specific step will be injected */}
    </div>
  );
};

export default OnboardingLayout;
