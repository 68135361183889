// supabaseClient.js
import { createClient } from '@supabase/supabase-js'
const BASE_URL = process.env.REACT_APP_API_URL || 'https://dentalexpressserver.azurewebsites.net';
//const BASE_URL = 'http://localhost:3001'

const supabaseUrl = "https://cnpozvvusjsxkydugtlg.supabase.co"
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImNucG96dnZ1c2pzeGt5ZHVndGxnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTkyOTUxMTYsImV4cCI6MjAxNDg3MTExNn0.bRrcrO1pbKmIF7KXDJn03pTSQhx9VxYA4hGFnbFY3UA'

export const supabase = createClient(supabaseUrl, supabaseAnonKey)


const writeToDatabase = async (email, practiceId) => {
  try {
    const response = await fetch(`${BASE_URL}/api/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, practiceId }),
    });

    if (!response.ok) {
      throw new Error('Failed to write user data to the database');
    }

    return response.json();
  } catch (error) {
    console.error('Error in writeToDatabase:', error);
    throw error;
  }
};

// signUp function to be used in your React components


export const signIn = async (email, password) => {
  try {
    const { error } = await supabase.auth.signInWithPassword({ email, password });
    console.log("error signing up ", error)
    if (error) throw error;

    const { data: session, error: sessionError } = await supabase.auth.getSession();

    if (sessionError) throw sessionError;

    return session;
  } catch (error) {
    console.error('Error signing in:', error);
    throw error;
  }
};


  export const signUp = async (email, password, practiceId) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email,  // Use the email argument passed to the function
        password,  // Use the password argument passed to the function
      });
  
      if (error) {
        throw error;
      }
  
      console.log(data); // Log data to see what is returned
  
      // Assuming data contains user information, and you need the user's ID
      // Make sure to check the structure of the returned data object
      
      if (data.user) {
        // send the confirm email 
        // const { error: emailError } = await supabase.auth.api.sendMagicLinkEmail(email);
        // if (emailError) {
        //   console.error('Error sending confirmation email:', emailError);
        //   // Handle the error appropriately
        // } else {
        //   console.log('Confirmation email sent successfully');
        // }

        await writeToDatabase(email, practiceId);
      }  
  
      return { data };
    } catch (error) {
      console.error('Error signing up:', error);
      throw error; // Re-throw the error so that it can be caught and handled by the caller
    }
  };
  
  export const updatePassword = async (newPassword) => {
    const { data, error } = await supabase.auth.updateUser({ password: newPassword });
    return { data, error };
  };

  export const resetPassword = async (email) => {
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: 'http://app.hemingwayai.co/updatepassword', // Update with your password update page URL
    });
    return { data, error };
};