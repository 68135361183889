import React, { useState, useEffect, useContext } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarPage.css'; // Your existing CSS
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import MainContainer from '../../components/MainContainer';
import { getCalendarDates } from '../../services/scheduleService';
import { useNavigate } from 'react-router-dom';
import DemoBanner from '../../components/DemoBanner';
import { NotificationContext } from '../../NotificationContext';


const localizer = momentLocalizer(moment);


const CalendarPage = () => {

    const navigate = useNavigate();

    const { isDemoRunning } = useContext(NotificationContext);

    // const hygienistResources = [
    //     { resourceId: 'Kyle', resourceTitle: 'Kyle' },
    //     { resourceId: 'Karen', resourceTitle: 'Karen' },
    //     { resourceId: 'Ken', resourceTitle: 'Ken' }
    // ];

    const [hygienistResources, setHygienistResources] = useState([]);

    const [events, setEvents] = useState([]);

    const minTime = new Date();
    minTime.setHours(7, 0, 0, 0);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                var practiceId = ""
                if (localStorage.getItem('practiceId')) {
                    practiceId = localStorage.getItem('practiceId');
                    console.log(practiceId);
                    // Use practiceId here
                } else {
                    console.log('No practiceId found in local storage');
                    navigate('/'); 
                }

                // Replace with your practice ID, start date, and end date
                const fetchedEvents = await getCalendarDates(practiceId, "2024-01-15T08:00:00", "2024-12-31T11:00:00");

                const fetchHygienistResources = async (practiceId) => {
                    try {
                        let resources;
                        if (practiceId === "Jemin") 
                        {
                            // Fetch resources specifically for 'Jemin'
                            resources = [
                                { resourceId: "OP01", resourceTitle: "OP01" },
                                { resourceId: "OP02", resourceTitle: "OP02" }
                                // Add more default resources as needed
                            ];
                        }
                        else if (practiceId === "GraceDental")
                        {
                            // Fetch resources specifically for 'Jemin'
                            resources = [
                                { resourceId: "OP05", resourceTitle: "OP05" },
                                { resourceId: "OP06", resourceTitle: "OP06" }
                                // Add more default resources as needed
                            ];
                        } 
                        else if (practiceId === "starBrite")
                        {
                            resources = [
                                { resourceId: "1", resourceTitle: "1" },
                                { resourceId: "2", resourceTitle: "2" },
                                { resourceId: "3", resourceTitle: "3" },
                                { resourceId: "4", resourceTitle: "4" }];
                        } 
                        else if (practiceId === "Jemin2")
                        {
                            resources = [
                                { resourceId: "OP03", resourceTitle: "OP03" }];
                        } 
                        else if (practiceId === "Chua")
                        {
                            resources = [
                                { resourceId: "1", resourceTitle: "1" },
                                { resourceId: "2", resourceTitle: "2" },
                                { resourceId: "3", resourceTitle: "3" },
                                { resourceId: "4", resourceTitle: "4" },
                                { resourceId: "5", resourceTitle: "5" },
                                { resourceId: "6", resourceTitle: "6" }];
                        } 
                        else if (practiceId === "den")
                        {
                            resources = [
                                { resourceId: "Hyg1", resourceTitle: "Hyg1" },
                                { resourceId: "Hyg2", resourceTitle: "Hyg2" },
                                { resourceId: "Hyg3", resourceTitle: "Hyg3" },
                                { resourceId: "Hyg4", resourceTitle: "Hyg4" },
                                { resourceId: "Hyg5", resourceTitle: "Hyg5" }];
                        } 
                        else if (practiceId === "wynn")
                        {
                            resources = [
                                { resourceId: "Luis", resourceTitle: "Luis" },
                                { resourceId: "Amber", resourceTitle: "Amber" },
                                { resourceId: "Melissa", resourceTitle: "Melissa" },
                                { resourceId: "Cody", resourceTitle: "Cody" },
                                { resourceId: "overflow", resourceTitle: "overflow" }];
                        } 
                        else if (practiceId === "IAS")
                        {
                            resources = [
                                { resourceId: "Hyg 1 - Temp", resourceTitle: "Hyg 1 - Temp" },
                                { resourceId: "Hyg 2 -Anna", resourceTitle: "Hyg 2 -Anna" }];
                        } 
                        else if (practiceId === "nkc1")
                        {
                            resources = [
                                { resourceId: "OP_1", resourceTitle: "OP_1" },
                                { resourceId: "OP_2", resourceTitle: "OP_2" },
                                { resourceId: "OP_3", resourceTitle: "OP_3" },
                                { resourceId: "OP_7", resourceTitle: "OP_7" },
                                { resourceId: "OP_8", resourceTitle: "OP_8" },
                                { resourceId: "OP_9", resourceTitle: "OP_9" }
                                // Add more default resources as needed
                            ];
                        }
                        else if (practiceId === "starBriteFremont")
                        {
                            resources = [
                                { resourceId: "1", resourceTitle: "1" },
                                { resourceId: "2", resourceTitle: "2" },
                                { resourceId: "3", resourceTitle: "3" },
                                { resourceId: "4", resourceTitle: "4" },
                                { resourceId: "5", resourceTitle: "5" }
                                // Add more default resources as needed
                            ];
                        }
                        else 
                        {
                            // Set to default values if practiceId is not 'Jemin'
                            resources = [
                                { resourceId: 'Kyle', resourceTitle: 'Kyle' },
                                { resourceId: 'Karen', resourceTitle: 'Karen' },
                                { resourceId: 'Ken', resourceTitle: 'Ken' }
                                // Add more default resources as needed
                            ];
                        }
                        setHygienistResources(resources);
                    } catch (error) {
                        console.error('Error fetching hygienist resources:', error);
                    }
                };

                fetchHygienistResources(practiceId);

                const transformedEvents = fetchedEvents.map(event => {
                    // Check if 'PatientName' contains 'Yeol' and print the event
                    if (event.PatientName && event.PatientName.includes('Yeol')) {
                        console.log(event);
                    }
                
                    return {
                        ...event,
                        title: event.PatientName,
                        start: new Date(event.Time),
                        end: event.endTime ? new Date(event.endTime) : new Date(new Date(event.Time).setHours(new Date(event.Time).getHours() + 1)), 
                        resourceId: event.Column
                    };
                });
                
                setEvents(transformedEvents);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, [navigate]);

    

    return (
        <>
            {isDemoRunning && <DemoBanner />} 
            <Header/>
            <div className="flex-container">
                <Sidebar />
                <MainContainer title="Schedule">
                    <div className="schedule-container">
                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            style={{ height: 700 }}
                            resources={hygienistResources}
                            resourceIdAccessor="resourceId"
                            resourceTitleAccessor="resourceTitle"
                            defaultView='day'
                            min={minTime} // Set the minimum time to 8:00 AM
                        />
                    </div>
                </MainContainer>
            </div>
        </>
    );
};

export default CalendarPage;
