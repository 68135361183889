// src/components/ChatWidget.js
import React, { useState } from 'react';
import './chatWidget.css';

const ChatWidget = () => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isExistingPatient, setIsExistingPatient] = useState(false);
    const [chatVisible, setChatVisible] = useState(false);
    const [messages, setMessages] = useState([]);
    // const [practiceId, setPracticeId] = useState('Sams Practice');
    const practiceId = 'Sams Practice'; 
    const [isTyping, setIsTyping] = useState(false);

    const serverUrl = 'https://dentalexpressserver.azurewebsites.net';
    // const serverUrl = 'http://localhost:3001';

    const validateAndFormatPhoneNumber = (phoneNumber) => {
        if (/^\+\d{11}$/.test(phoneNumber)) {
            return phoneNumber;
        } else if (/^\d{10}$/.test(phoneNumber)) {
            return '+1' + phoneNumber;
        } else {
            alert('Invalid phone number format. Please enter a valid 10-digit number or in the format +1XXXXXXXXXX.');
            return null;
        }
    };


    const fetchWithTimeout = (url, options, timeout = 180000) => { // 3 minutes timeout
        return Promise.race([
            fetch(url, options),
            new Promise((_, reject) =>
                setTimeout(() => reject(new Error('Request timed out')), timeout)
            )
        ]);
    };

    const submitForm = () => {
        if (name && phoneNumber) {
            const formattedPhoneNumber = validateAndFormatPhoneNumber(phoneNumber);
            console.log("formattedPhoneNumber1 ", formattedPhoneNumber)
            if (!formattedPhoneNumber) {
                return;
            }
            fetchWithTimeout(`${serverUrl}/submit-widget`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ name, phoneNumber: formattedPhoneNumber, isExistingPatient, practiceId })
            }, 180000) 
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    setChatVisible(true);
                    setMessages(prevMessages => [
                        ...prevMessages, 
                        { sender: 'system', text: data.outreachMessage }
                    ]);
                } else {
                    alert('Error: ' + data.message);
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
        } else {
            alert('Please enter all required information.');
        }
    };
    

    const handleSendMessage = (event) => {
        event.preventDefault();
        const message = event.target.message.value;
        if (message.trim()) {

            const formattedPhoneNumber = validateAndFormatPhoneNumber(phoneNumber);
        if (!formattedPhoneNumber) {
            return;
        }

            setMessages(prevMessages => [
                ...prevMessages,
                { sender: 'user', text: message }
            ]);
            setIsTyping(true);

            fetchWithTimeout(`${serverUrl}/recieve-widget`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ message, phoneNumber: formattedPhoneNumber, practiceId })
            }, 180000)
            .then(response => response.json())
            .then(data => {
                setIsTyping(false);
                setMessages(prevMessages => [
                    ...prevMessages,
                    { sender: 'system', text: data.response }
                ]);
            })
            .catch(error => {
                console.error('Error:', error);
                setIsTyping(false);
            });

            event.target.reset();
        }
    };

    return (
        <div id="chat-widget">
            {!chatVisible ? (
                <>
                    <h3>Enter your details</h3>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Your name"
                    />
                    <input
                        type="text"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Your phone number"
                    />
                    <div>
                        <label>
                            <input
                                type="checkbox"
                                checked={isExistingPatient}
                                onChange={() => setIsExistingPatient(!isExistingPatient)}
                            />
                            Existing Patient
                        </label>
                    </div>
                    <button onClick={submitForm}>Submit</button>
                </>
            ) : (
                <div id="chat-interface">
                    <div id="chat-messages">
                        {messages.map((message, index) => (
                            <div key={index} className={`chat-message ${message.sender}`}>
                                {message.text}
                            </div>
                        ))}
                        {isTyping && (
                            <div className="chat-message system typing">
                                Typing...
                            </div>
                        )}
                    </div>
                    <form id="chat-form" onSubmit={handleSendMessage}>
                        <input type="text" name="message" placeholder="Type your message..." />
                        <button type="submit">Send</button>
                    </form>
                </div>
            )}
        </div>
    );
};

export default ChatWidget;
