import React from 'react';
import './DemoBanner.css'; // Make sure the path is correct

const DemoBanner = () => {
    return (
        <div style={{ 
            background: '#28a745', // Solid green background
            color: 'white',
            textAlign: 'center',
            fontWeight: 'bold', // Make text bolder
            fontSize: '20px' // Increase font size
        }}>
            Demo setup is in progress
        </div>
    );
};
export default DemoBanner;