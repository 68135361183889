import React from 'react';
import './FormInput.css'; // Assuming you have a separate CSS file for styling

const FormInput = ({ label, type, value, onChange, style }) => {
  return (
    <div className="form-input">
      <label>{label}</label>
      <input type={type} value={value} onChange={onChange} style={style ? style : null} />
    </div>
  );
};

export default FormInput;
