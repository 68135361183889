import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "./Dialog";

const InfoPanel = ({ title, menuItems, contentData }) => {
    const [activeItem, setActiveItem] = useState(menuItems[0].name);

    const getContentForActiveItem = () => {
        const activeItemContent = menuItems.find(item => item.name === activeItem)?.content;
        return contentData[activeItemContent] || [];
    };

    return (
        <DialogContent className="!w-[900px] !h-[80vh] !max-h-[700px] !translate-x-[-50%] !translate-y-[-50%] !p-0 flex flex-col overflow-hidden">
            <DialogHeader className="flex-shrink-0 p-6 pb-2">
                <DialogTitle>{title}</DialogTitle>
            </DialogHeader>
            <div className="flex flex-1 overflow-hidden p-6 pt-2">
                <div className="w-1/3 pr-4 flex flex-col border-r overflow-hidden">
                    <nav className="flex-1 overflow-y-auto pr-2">
                        {menuItems.map((item) => (
                            <button
                                key={item.name}
                                onClick={() => setActiveItem(item.name)}
                                className={`flex items-center gap-3 rounded-lg px-3 py-2 text-sm font-medium transition-all hover:bg-muted hover:text-primary w-full mb-2 ${
                                    activeItem === item.name ? 'bg-muted text-primary' : 'text-muted-foreground'
                                }`}
                            >
                                {item.icon}
                                {item.name}
                            </button>
                        ))}
                    </nav>
                </div>
                <div className="flex-1 pl-4 overflow-hidden flex flex-col">
                    <h3 className="text-lg font-semibold mb-4">{activeItem}</h3>
                    <div className="flex-1 overflow-y-auto pr-2">
                        <ul className="space-y-2">
                            {getContentForActiveItem().map((item, index) => (
                                <li key={index} className="flex items-start">
                                    <span className="mr-2">•</span>
                                    <span>{item}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </DialogContent>
    );
};

export default InfoPanel;