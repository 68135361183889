import React, { useState } from 'react';
import './SettingsPage.css';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const SettingsPage = () => {
    const [campaigns, setCampaigns] = useState([
        {
            id: 1,
            campaignName: "Six month reactivation outreach",
            monthsSinceLastCleaning: "6",
            daysSinceLastCleaning: "0",
            standardMessage: "Hey {Patient Name} - it's been six months since your last cleaning. Insurance typically covers two cleaning a year. We have an opening for {date}. Can you make it?",
        },
        {
            id: 2,
            campaignName: "One day follow up",
            monthsSinceLastCleaning: "0",
            daysSinceLastCleaning: "1",
            standardMessage: "Hey {Patient Name} - hope you're cleaning went well! We realized you left without booking your next cleaning. How is {date}?",
        }
    ]);
    const [showPopup, setShowPopup] = useState(false);
    const [newCampaign, setNewCampaign] = useState({
        monthsSinceLastCleaning: '',
        standardMessage: '',
        messagesPerDay: '' // New field for the form
    });

    const handleCreateCampaign = () => {
        setShowPopup(true);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const campaignToAdd = {
            ...newCampaign,
            id: campaigns.length + 1,
            numOfPatients: 300, // Set number of patients to 300
            numReactivated: 0, // Set number reactivated to 0
            messagesPerDay: parseInt(newCampaign.messagesPerDay, 10) || 0 // Ensure messagesPerDay is an integer
        };
        setCampaigns([...campaigns, campaignToAdd]);
        setShowPopup(false);
        setNewCampaign({ monthsSinceLastCleaning: '', standardMessage: '', messagesPerDay: '' }); // Reset form fields
    };

    return (
        <>
            <Header />
            <div className="flex-container">
                <Sidebar />
                <div className="main-content">
                    <div className="campaigns-container">
                        <h3> Active Campaigns</h3>
                        <button className="create-campaign-btn" onClick={handleCreateCampaign}>Create a Campaign</button>
                        <div className="campaigns-list">
                        
                            {campaigns.map((campaign) => (
                                <div key={campaign.id} className="campaign">
                                <div className="campaign-header">
                                    <div className="campaign-title">{campaign.campaignName}</div>
                                    <div className="campaign-actions">
                                        <a href="/edit">Edit</a>
                                        <a href="/delete">Delete</a>
                                    </div>
                                </div>
                                <div className="campaign-detail">
                                    <div className="detail-title">Message outreach</div>
                                    <div className="detail-content">{campaign.monthsSinceLastCleaning} months and {campaign.daysSinceLastCleaning} days since last cleaning</div>
                                </div>
                                <div className="campaign-detail">
                                    <div className="detail-title">Outbound message</div>
                                    <div className="detail-content">{campaign.standardMessage}</div>
                                </div>
                            </div>
                            
                            
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            {showPopup && (
    <div className="popup">
        <div className="popup-inner">
            <form onSubmit={handleSubmit}>
                <h3>Create a Campaign</h3>
                <h4> Campaign Name</h4>
                <input
                    type="text"
                    placeholder="Campaign Name"
                    value={newCampaign.campaignName}
                    onChange={(e) => setNewCampaign({ ...newCampaign, campaignName: e.target.value })}
                    required
                />
                 <h4> Outreach to unscheduled patient after:</h4>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
               
                    <input
                        type="number"
                        placeholder="Months"
                        value={newCampaign.durationMonths}
                        onChange={(e) => setNewCampaign({ ...newCampaign, durationMonths: e.target.value })}
                        required
                        style={{ marginRight: '10px', flex: 1 }}
                    />
                    <input
                        type="number"
                        placeholder="Days"
                        value={newCampaign.durationDays}
                        onChange={(e) => setNewCampaign({ ...newCampaign, durationDays: e.target.value })}
                        required
                        style={{ flex: 1 }}
                    />
                </div>
                <h4>Outreach Message</h4>
                <textarea
                    placeholder="Standard Message"
                    value={newCampaign.standardMessage}
                    onChange={(e) => setNewCampaign({ ...newCampaign, standardMessage: e.target.value })}
                    required
                    style={{ width: '100%', height: '100px', marginBottom: '20px' }}
                ></textarea>
  
            
                <div style={{ marginTop: '20px' }}>
                    <button type="submit" style={{ marginRight: '10px' }}>Add Campaign</button>
                    <button type="button" onClick={() => setShowPopup(false)}>Cancel</button>
                </div>
            </form>
        </div>
    </div>
)}




        </>
    );
};

export default SettingsPage;