const BASE_URL = process.env.REACT_APP_API_URL || 'https://dentalexpressserver.azurewebsites.net';

function fetchFromAPI(endpoint) {
    return fetch(`${BASE_URL}${endpoint}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        });
}

async function getNotifCount(practiceId) {
    try {
        console.log("getting notif count for practiceId: ", practiceId)
        const data = await fetchFromAPI(`/api/notificationCount/${practiceId}`);
        return data.notificationCount; // Assuming the API returns an object with a notificationCount property
    } catch (error) {
        console.error('Error fetching notification count:', error);
        throw error; // Re-throw the error if you want to handle it where the function is called
    }
}


export { getNotifCount };