// import React, { useState, useEffect } from 'react';
import './PatientsToolbar.css';


const PatientsToolbar = () => {

    return (
        <div className='toolbar'>

            

        </div>
    );
};

export default PatientsToolbar;

