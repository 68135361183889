import React from 'react';
import './ActionCard.css'; // Ensure the CSS is appropriately renamed and adjusted

const ActionCard = ({
    title,
    primaryText,
    secondaryTexts = [],
    onPrimaryAction,
    onSecondaryAction,
    secondaryActionLabel, // New prop for the secondary action label
    actionCompleted
}) => {

    const handlePrimaryAction = () => {
        onPrimaryAction && onPrimaryAction();
    };

    const handleSecondaryAction = () => {
        onSecondaryAction && onSecondaryAction();
    };

    return (
        <div className="action-card">
            <div className='text-container'>
                <h4>{title}</h4>
                <p><b>{primaryText.label}:</b> {primaryText.value}</p>
                {secondaryTexts.map((text, index) => (
                    <p key={index}><b>{text.label}:</b> {text.value}</p>
                ))}
            </div>
            <div className="actions">
                {!actionCompleted ? (
                    <>
                        <button onClick={handlePrimaryAction}>{primaryText.actionLabel}</button>
                        {/* Use the new secondaryActionLabel prop for the second button */}
                        <button onClick={handleSecondaryAction}>{secondaryActionLabel}</button>
                    </>
                ) : (
                    <p className="status-tag">{`Complete: ${actionCompleted}`}</p>
                )}
            </div>
        </div>
    );
};

export default ActionCard;
