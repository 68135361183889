import React, { useState, useEffect, useContext } from 'react';
import './PatientsPage.css'; // Your existing CSS
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import MainContainer from '../../components/MainContainer';
import { getPatientsByPractice } from '../../services/patientsService';
import Table from '../../components/Table';
import PatientsToolbar from '../../components/PatientsToolbar';
import { useNavigate } from 'react-router-dom'; 
import DemoBanner from '../../components/DemoBanner';
import { NotificationContext } from '../../NotificationContext';
import Tabs from '../../components/Tabs';

const PatientsPage = () => {

    const [patients, setPatients] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { isDemoRunning } = useContext(NotificationContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentView, setCurrentView] = useState('sch');

    const tabs = [
        { id: 'sch', label: 'Scheduled Patients' },
        { id: 'un', label: 'Unscheduled Patients' }
      ];
    
    const [columns, setColumns] = useState([
        { header: 'Name', key: 'Name' },
        { header: 'Next Appointment Time', key: 'NextTime' },
        { header: 'Chair', key: 'opChair' },
        { header: 'Type', key: 'appointmentType' },
        { header: 'Status', key: 'appointmentStatus' },
    ]);

    console.log("isDemoRunning ", isDemoRunning)

    useEffect(() => {

        if (currentView === 'sch') {
            setColumns([
                { header: 'Name', key: 'Name' },
                { header: 'Next Appointment Time', key: 'NextAppointment' },
                { header: 'Chair', key: 'opChair' },
                { header: 'Type', key: 'appointmentType' },
                { header: 'Status', key: 'NextAppointmentStatus' },
            ]);
        } else {
            setColumns([
                { header: 'Name', key: 'Name' },
                { header: 'Last Cleaning Date', key: 'LastCleaningDate' },
                { header: 'Status', key: 'status' },
                { header: 'Summary', key: 'summary' }
            ]);
        }

        var practiceId = ""
        if (localStorage.getItem('practiceId')) {
            practiceId = localStorage.getItem('practiceId');
            console.log(practiceId);
            // Use practiceId here
        } else {
            console.log('No practiceId found in local storage');
            navigate('/'); 
        }

        if (practiceId === "den") {
            setColumns([
                { header: 'Name', key: 'Name' },
                { header: 'Conversation Status', key: 'ConversationStatus' },
                { header: 'Appointment Status', key: 'ScheduleStatus' },
                { header: 'Last Message', key: 'lastMessagedTime' }
            ]);
        }

        var specialName = "fakename"
        if (localStorage.getItem('name')) {
            specialName = localStorage.getItem('name');
            console.log(specialName);
            // Use practiceId here
        } else {
            console.log('No name found in local storage');
            if(practiceId.startsWith("tester"))
            {
                specialName = "test1"
            }
        }
        console.log("searchTerm ", searchTerm);
        getPatientsByPractice(practiceId, specialName, currentPage, 10, searchTerm, currentView === 'sch')
            .then(data => {
                const modifiedPatients = data.patients.map(patient => ({
                    ...patient,
                    NextAppointmentStatus: patient.NextAppointmentStatus || 'Unscheduled',
                    url: `/patients/${patient.ID}`, // Assuming 'id' is the field in your patient object
                    lastMessagedTime: patient.lastMessagedTime ? new Date(patient.lastMessagedTime).toLocaleString() : 'N/A'
                }));
                
                console.log("modifiedPatients ", modifiedPatients)
                setPatients(modifiedPatients);
            })
            .catch(error => setError(error));
    
    }, [navigate, currentPage, searchTerm, currentView]); 


    if (error) {
        return <div>Error: {error.message}</div>;
    }
    return (
        <>
            {isDemoRunning && <DemoBanner />} 
            <Header/>
            <div className="flex-container">
                <Sidebar />
                <MainContainer title="Patients">
                <Tabs tabs={tabs} currentView={currentView} setCurrentView={setCurrentView} />
                    <div className="patients-container">
                        <PatientsToolbar/>
                        <input
                            type="text"
                            placeholder="Search Patients..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ margin: '20px 0', padding: '10px' }}
                        />

                    <Table data={patients} columns={columns} />

                    </div>
                </MainContainer>
            </div>
            <div className="pagination-buttons" style={{ position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: '1000' }}>
                <button 
                    onClick={() => setCurrentPage(currentPage - 1)} 
                    disabled={currentPage === 1}
                    style={{ marginRight: '10px' }}
                >
                    Previous Page
                </button>
                <button onClick={() => setCurrentPage(currentPage + 1)}>
                    Next Page
                </button>
            </div>

        </>
    );
};

export default PatientsPage;
