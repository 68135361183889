import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import { Card, CardContent, CardHeader, CardTitle } from "../ui/Card";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../ui/Table";

const PatientPage = () => {
  const { id } = useParams();
  const [practiceId, setPracticeId] = useState(localStorage.getItem('practiceId') || '');

  // Expanded patient data
  const patients = [
    { 
      id: 1, 
      name: "John Doe", 
      age: 35,
      nextAppointment: "2024-09-01",
      lastVisit: "2024-03-15",
      insuranceProvider: "HealthCare Inc.",
      outstandingBalance: 0,
      appointmentHistory: [
        { date: "2024-03-15", procedure: "Dental Cleaning" },
        { date: "2023-09-20", procedure: "Cavity Filling" },
        { date: "2023-03-10", procedure: "X-Ray" }
      ],
      familyMembers: [
        { name: "Jane Doe", relationship: "Spouse" },
        { name: "Jimmy Doe", relationship: "Son" }
      ]
    },
    { 
      id: 2, 
      name: "Jane Smith", 
      age: 28,
      nextAppointment: "2024-08-15",
      lastVisit: "2024-02-20",
      insuranceProvider: "DentalCare Plus",
      outstandingBalance: 75.50,
      appointmentHistory: [
        { date: "2024-02-20", procedure: "Root Canal" },
        { date: "2023-08-05", procedure: "Dental Cleaning" }
      ],
      familyMembers: [
        { name: "John Smith", relationship: "Spouse" }
      ]
    },
    { 
      id: 3, 
      name: "Alice Johnson", 
      age: 42,
      nextAppointment: "N/A",
      lastVisit: "2023-11-30",
      insuranceProvider: "FamilyDental",
      outstandingBalance: 0,
      appointmentHistory: [
        { date: "2023-11-30", procedure: "Dental Cleaning" },
        { date: "2023-05-15", procedure: "Teeth Whitening" }
      ],
      familyMembers: [
        { name: "Bob Johnson", relationship: "Spouse" },
        { name: "Charlie Johnson", relationship: "Son" },
        { name: "Diana Johnson", relationship: "Daughter" }
      ]
    },
    { 
      id: 4, 
      name: "Robert Brown", 
      age: 50,
      nextAppointment: "2024-07-22",
      lastVisit: "2024-01-10",
      insuranceProvider: "Senior Smile",
      outstandingBalance: 120.75,
      appointmentHistory: [
        { date: "2024-01-10", procedure: "Dental Implant Consultation" },
        { date: "2023-07-05", procedure: "Dental Cleaning" }
      ],
      familyMembers: [
        { name: "Mary Brown", relationship: "Spouse" }
      ]
    },
    { 
      id: 5, 
      name: "Emily Davis", 
      age: 31,
      nextAppointment: "2024-08-05",
      lastVisit: "2024-02-28",
      insuranceProvider: "HealthCare Inc.",
      outstandingBalance: 0,
      appointmentHistory: [
        { date: "2024-02-28", procedure: "Dental Cleaning" },
        { date: "2023-08-30", procedure: "Cavity Filling" }
      ],
      familyMembers: []
    },
    { 
      id: 6, 
      name: "Michael Wilson", 
      age: 45,
      nextAppointment: "2024-09-18",
      lastVisit: "2024-03-22",
      insuranceProvider: "DentalCare Plus",
      outstandingBalance: 50.00,
      appointmentHistory: [
        { date: "2024-03-22", procedure: "Root Canal" },
        { date: "2023-09-10", procedure: "Dental Cleaning" }
      ],
      familyMembers: [
        { name: "Sarah Wilson", relationship: "Spouse" },
        { name: "Tom Wilson", relationship: "Son" }
      ]
    },
    { 
      id: 7, 
      name: "Sarah Taylor", 
      age: 39,
      nextAppointment: "2024-07-30",
      lastVisit: "2024-01-25",
      insuranceProvider: "FamilyDental",
      outstandingBalance: 0,
      appointmentHistory: [
        { date: "2024-01-25", procedure: "Dental Cleaning" },
        { date: "2023-07-20", procedure: "X-Ray" }
      ],
      familyMembers: [
        { name: "James Taylor", relationship: "Spouse" },
        { name: "Emma Taylor", relationship: "Daughter" }
      ]
    },
    { 
      id: 8, 
      name: "David Martinez", 
      age: 55,
      nextAppointment: "N/A",
      lastVisit: "2023-12-05",
      insuranceProvider: "Senior Smile",
      outstandingBalance: 200.25,
      appointmentHistory: [
        { date: "2023-12-05", procedure: "Denture Fitting" },
        { date: "2023-06-15", procedure: "Dental Cleaning" }
      ],
      familyMembers: [
        { name: "Lisa Martinez", relationship: "Spouse" }
      ]
    },
    { 
      id: 9, 
      name: "Jennifer Anderson", 
      age: 33,
      nextAppointment: "2024-08-12",
      lastVisit: "2024-02-15",
      insuranceProvider: "HealthCare Inc.",
      outstandingBalance: 0,
      appointmentHistory: [
        { date: "2024-02-15", procedure: "Dental Cleaning" },
        { date: "2023-08-20", procedure: "Cavity Filling" }
      ],
      familyMembers: [
        { name: "Mike Anderson", relationship: "Spouse" },
        { name: "Lily Anderson", relationship: "Daughter" }
      ]
    },
    { 
      id: 10, 
      name: "Christopher Lee", 
      age: 47,
      nextAppointment: "2024-09-07",
      lastVisit: "2024-03-10",
      insuranceProvider: "DentalCare Plus",
      outstandingBalance: 25.00,
      appointmentHistory: [
        { date: "2024-03-10", procedure: "Dental Cleaning" },
        { date: "2023-09-05", procedure: "Crown Fitting" }
      ],
      familyMembers: [
        { name: "Angela Lee", relationship: "Spouse" },
        { name: "Ryan Lee", relationship: "Son" },
        { name: "Sophia Lee", relationship: "Daughter" }
      ]
    }
  ];

  // Find the correct patient based on the id from URL params
  const patient = patients.find(p => p.id === parseInt(id)) || null;

  if (!patient) {
    return (
      <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
        <div className="hidden border-r bg-muted/40 md:block">
          <Sidebar practiceId={practiceId}/>
        </div>
        <div className="flex flex-col min-h-screen">
          <MainContent title="Patient Not Found">
            <p>Sorry, the patient with ID {id} was not found.</p>
          </MainContent>
        </div>
      </div>
    );
  }

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <Sidebar practiceId={practiceId}/>
      </div>
      
      <div className="flex flex-col min-h-screen">
        <MainContent title={`Patient: ${patient.name}`}>
          <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3">
            <Card>
              <CardHeader>
                <CardTitle>Personal Information</CardTitle>
              </CardHeader>
              <CardContent>
                <p><strong>Age:</strong> {patient.age}</p>
                <p><strong>Last Visit:</strong> {patient.lastVisit}</p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Insurance Information</CardTitle>
              </CardHeader>
              <CardContent>
                <p><strong>Provider:</strong> {patient.insuranceProvider}</p>
                <p><strong>Outstanding Balance:</strong> ${patient.outstandingBalance.toFixed(2)}</p>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Next Appointment</CardTitle>
              </CardHeader>
              <CardContent>
                <p>{patient.nextAppointment}</p>
              </CardContent>
            </Card>
          </div>

          <Card className="mt-4">
            <CardHeader>
              <CardTitle>Appointment History</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Date</TableHead>
                    <TableHead>Procedure</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {patient.appointmentHistory.map((appointment, index) => (
                    <TableRow key={index}>
                      <TableCell>{appointment.date}</TableCell>
                      <TableCell>{appointment.procedure}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          <Card className="mt-4">
            <CardHeader>
              <CardTitle>Family Members</CardTitle>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Name</TableHead>
                    <TableHead>Relationship</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {patient.familyMembers.map((member, index) => (
                    <TableRow key={index}>
                      <TableCell>{member.name}</TableCell>
                      <TableCell>{member.relationship}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CardContent>
          </Card>
        </MainContent>
      </div>
    </div>
  );
};

export default PatientPage;