import React, { useState, useEffect, useContext } from 'react';
import './RunDemo.css'; // CSS for RunDemo page
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import MainContainer from '../../components/MainContainer';
import { runDemo } from '../../services/RunDemoService'
import Spinner from 'react-bootstrap/Spinner'; 
import { useNavigate } from 'react-router-dom'; 
import DemoBanner from '../../components/DemoBanner';
import { NotificationContext } from '../../NotificationContext';
import NewSidebar from '../../ui/Sidebar';

const RunDemoPage = () => {
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const [selectedFeature, setSelectedFeature] = useState('');
    const { isDemoRunning } = useContext(NotificationContext);
    const [globalPracticeId, setGlobalPracticeId] = useState('');
    const [apptDate, setApptDate] = useState('');


    const handleApptDateChange = (event) => {
        setApptDate(event.target.value);
    };

    const validateAndFormatApptDate = (date) => {
        const regex = /^\d{4}-\d{2}-\d{2}T\d{2}$/;
        if (!regex.test(date)) {
            alert('Please enter the date and time in the format YYYY-MM-DDTHH');
            return null;
        }
        return date + ':00:00'; // Add minutes and seconds
    };

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const handleFeatureChange = (event) => {
        setSelectedFeature(event.target.value);
        console.log("selectedFeature ",  event.target.value)
        console.log("demoRunning ",  isDemoRunning)
    };

    const validateAndFormatPhoneNumber = (phoneNumber) => {
        // Check if the phone number is already in the desired format
        if (/^\+\d{11}$/.test(phoneNumber)) {
            return phoneNumber;
        }
        // Check if the phone number has 10 digits and prepend '+1'
        else if (/^\d{10}$/.test(phoneNumber)) {
            return '+1' + phoneNumber;
        }
        // If neither condition is met, return null to indicate an invalid format
        else {
            setMessage('Invalid phone number format. Please enter a valid 10-digit number or in the format +1XXXXXXXXXX.');
            return null;
        }
    };

    useEffect(() => {
        const practiceId = localStorage.getItem('practiceId') || '';
        // If the stored practiceId is "tester", pre-load the name and phone number
        if (practiceId === "tester") {
            setName('test1');
            setPhoneNumber('1111111111');
        }
        else if (practiceId.startsWith("tester")) {
            // Extract the number part assuming it's the character right after "tester"
            let numberPart = practiceId.substring(6); // Get everything after "tester"
        
            // Use the numberPart directly to set name and phone number dynamically
            setName(`test1`);
            setPhoneNumber('1'.repeat(9) + numberPart); // assuming numberPart is a single digit for phone number
        }

        setGlobalPracticeId(practiceId);
    }, []);


    async function triggerNewDemo() {
        setIsLoading(true);
        try {

            var practiceId = ""
            if (localStorage.getItem('practiceId')) {
                practiceId = localStorage.getItem('practiceId');
                console.log(practiceId);
                // Use practiceId here
            } else {
                console.log('No practiceId found in local storage');
                navigate('/'); 
            }

            const formattedPhoneNumber = validateAndFormatPhoneNumber(phoneNumber);
            console.log("old ", phoneNumber, "new ", formattedPhoneNumber)

            if (!formattedPhoneNumber) {
                setMessage('Please enter a valid phone number in the format +1XXXXXXXXXX or a 10-digit number.');
                setIsLoading(false);
                return;
            }

            let formattedApptDate = ""
            if (selectedFeature === 'feature1')
            {
                formattedApptDate = validateAndFormatApptDate(apptDate);
                if (!formattedApptDate) {
                    setIsLoading(false);
                    return;
                }
            }
            
            let demoType = 0; // Default value
            if (selectedFeature === 'feature1') {
                demoType = 0; // Value for feature1
            } 
            else if (selectedFeature === 'feature2') {
                demoType = 1; // Value for feature2
            }
            else if (selectedFeature === 'feature3') {
                demoType = 2; 
            }
            else if (selectedFeature === 'feature4') {
                demoType = 3; 
            }


            const response = await runDemo(name, formattedPhoneNumber, practiceId, demoType, false, "", formattedApptDate);
            console.log('Demo triggered with name:', name, 'phone number:', formattedPhoneNumber, 'practiceId:', practiceId);
    
            // Check the response status code
            if (response.status === 202) {
                localStorage.setItem('name', name);
                console.log("set local storage name to  ", name)
                setMessage('Demo setup has begun for your practice. Please wait while it is being configured.');
            } else {
                setMessage(`Demo started successfully`);
            }
        } catch (error) {
            console.error('Error:', error.message);
            if (error.status === 429) {
                setMessage('Demo has already been trigged. Please wait for it to complete');
            }
            else {
                setMessage('Error occurred: ' + error.message);
            }
        }
        finally {
            setIsLoading(false); 
        }
    };
    //     

    return (
        <>
            {isDemoRunning && <DemoBanner />} 
            <Header />
            <div className="flex-container">
                {console.log("Kane2", globalPracticeId)}
            <>{globalPracticeId === "VarinosDemo" ? <NewSidebar practiceId={globalPracticeId} /> : <Sidebar />}</>
                <MainContainer title="Run Demo">
                    <div className="run-demo-container">
                        <div className='demo-overview'>
                            <h2>Welcome to the Hemingway demo </h2>
                            <p> Enter your details below to receive a text from <b>Smiles Dental</b>, a fictional private practice. </p>
                            <p> The demo enables you to simulate two scenarios: </p>
                            <p> <b>Confirmation:</b> you can confirm the appointment or work with the AI-assistant to find a new time if you can't make it </p>
                            <p><b>Reactivation:</b> work with the assistant to find a time that works for you to come in for a cleaning </p>
                            <p><b>Missed Call Follow Up:</b> simulate a missed call scenario where an ai agent will reach out after a missed call </p>
                            <p><b>Voice Confirmation:</b> you can confirm or cancel an appointment via a voice conversation with our ai agent </p>
                            <p>Before writing into the calendar, new appointments will be surfaced in the <b>Activity</b> page. Please note the demo takes a few minutes to configure.  </p>
                        </div>
                        <form>
                            <div className="form-group">
                                <label htmlFor="name">Name:</label>
                                <input className='input-item'
                                    type="text"
                                    id="name"
                                    value={name}
                                    onChange={handleNameChange}
                                />
                            </div>
                            <div className="form-group">
                                
                                <label htmlFor="phoneNumber">Phone Number:</label>
                                <input
                                    className='input-item'
                                    type="tel"
                                    id="phoneNumber"
                                    value={phoneNumber}
                                    onChange={handlePhoneNumberChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="apptDate">Appointment Date and Time:</label>
                                <input
                                    className='input-item'
                                    type="text"
                                    id="apptDate"
                                    value={apptDate}
                                    onChange={handleApptDateChange}
                                    placeholder="YYYY-MM-DDTHH"
                                />
                            </div>
                            <div className="form-group">
                            <label>Confirmation</label>
                            <input 
                                type="radio" 
                                name="feature" 
                                value="feature1" 
                                onChange={handleFeatureChange} 
                            />

                            <label>Reactivation</label>
                            <input 
                                type="radio" 
                                name="feature" 
                                value="feature2" 
                                onChange={handleFeatureChange} 
                            />
                            
                            <label>Missed call follow up</label>
                            <input 
                                type="radio" 
                                name="feature" 
                                value="feature3" 
                                onChange={handleFeatureChange} 
                            />

                            <label>Voice Confirmation</label>
                            <input 
                                type="radio" 
                                name="feature" 
                                value="feature4" 
                                onChange={handleFeatureChange} 
                            />          
                            </div>

                            <button className = "demoButton" type="button" onClick={triggerNewDemo}>Trigger New Demo</button>
                            {isLoading && <div className="loading-spinner"><Spinner animation="border" /></div>}
                        </form>
                        {message && <p className="message">{message}</p>}
                    </div>
                </MainContainer>
            </div>
        </>
    );
};

export default RunDemoPage;
