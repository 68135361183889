import React, { useState, useEffect, useContext, useMemo } from 'react';
//import axios from 'axios'; // You might need to install axios if not already installed
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import MainContainer from '../../components/MainContainer';
import './Activity.css';
import { getActivities, confirmActivity, ManualHandleActivity, GetPracticeStats, getThrowsByPracticeId, markThrowDocumentResolved } from '../../services/ActivityService';
//import RescheduleRequest from '../../components/RescheduleRequest'; // Adjust the path as necessary
import { useNavigate } from 'react-router-dom'; 
import { NotificationContext } from '../../NotificationContext.js'
import DemoBanner from '../../components/DemoBanner';
//import AnalyticsContainer from '../../components/AnalyticsContainer';
import TableComponent from '../../components/TableComponent';
//import ThrowDocument from '../../components/ThrowDocument.js';
import ActionCard from '../../components/ActionCard.js';
import Tabs from '../../components/Tabs';
import ChatWidget from '../../components/chatWidget';


const ActivityPage = () => {

    const { refreshNotifications } = useContext(NotificationContext);

    const [currentView, setCurrentView] = useState('throwDocuments');
    const [activities, setActivities] = useState([]);
    const [practiceIdGlobal, setPracticeIdGlobal] = useState('');
    const navigate = useNavigate();

    const [practiceStats, setPracticeStats] = useState({
        patientsReactivated: 0,
        totalOverdue: 0,
    });

    const { isDemoRunning } = useContext(NotificationContext);

    const [throwDocuments, setThrowDocuments] = useState([]);

    const tabs = [
        { id: 'throwDocuments', label: 'Conversations that need help' },
        { id: 'activities', label: 'Appointment bookings' }
      ];
    
    const prodIds = useMemo(() => ['Jemin', 'Chua', 'wynn', "starBrite", "Jemin2", "GraceDental", "IAS", "nkc1", "starBriteFremont"], []);

    useEffect(() => {
        
        const fetchActivities = async () => {

            var practiceId = ""
            if (localStorage.getItem('practiceId')) {
                practiceId = localStorage.getItem('practiceId');
                setPracticeIdGlobal(practiceId);
                console.log(practiceId);
                // Use practiceId here
            } else {
                console.log('No practiceId found in local storage');
                navigate('/'); 
            }
            
            var practiceIdForActivities = practiceId;
            if(practiceId === "Admin")
            {
                practiceIdForActivities = prodIds;
            }

            try {
                const activitiesData = await getActivities(practiceIdForActivities);
                setActivities(activitiesData);
                console.log("Fetched activities:", activitiesData);
            } catch (error) {
                console.error('Error fetching activities:', error);
            }
        };

        fetchActivities();

        const fetchPracticeStats = async () => {
            var practiceId = ""
            if (localStorage.getItem('practiceId')) {
                practiceId = localStorage.getItem('practiceId');
                console.log(practiceId);
                // Use practiceId here
            } else {
                console.log('No practiceId found in local storage');
                navigate('/'); 
            }

            try {
                const stats = await GetPracticeStats(practiceId);
                if (stats && stats.length > 0) {
                    // Assuming the API returns an array with one element
                    setPracticeStats({
                        patientsReactivated: stats[0].PatientsReactivated || 0,
                        totalOverdue: stats[0].TotalOverdue || 0,
                    });
                }
            } catch (error) {
                console.error('Error fetching practice statistics:', error);
            }
        };

        fetchPracticeStats();

        const fetchThrowDocuments = async () => {
            
            var practiceId = localStorage.getItem('practiceId');
            if (practiceId) {
                var practiceIdForActivities = practiceId;
                if(practiceId === "Admin")
                {
                    practiceIdForActivities = prodIds;
                }

                try {
                    const throwsData = await getThrowsByPracticeId(practiceIdForActivities);
                    console.log("throwsData ", throwsData)
                    setThrowDocuments(throwsData);
                } catch (error) {
                    console.error('Error fetching throw documents:', error);
                }
            }
        };
    
        fetchThrowDocuments();
    }, [navigate, prodIds]);

    const updateActivityStatus = (activityId, bManual, bConfirm, sResult) => {
        setActivities(prevActivities => {
            const index = prevActivities.findIndex(a => a.id === activityId);
            if (index === -1) return prevActivities; // If activity not found, return the original array

            const newActivities = [...prevActivities];
            newActivities[index] = { ...newActivities[index], ActedOn: true, Confirmed: bConfirm, Manual: bManual, Result: sResult};
            return newActivities;
        });
    };

    const handleConfirm = (activity) => {
        const { id: documentId, name, practiceId, phoneNumber, time, previousTime, patientId, column } = activity;
        console.log('hello');
        console.log('Activity object:', activity);
        console.log('Sending:', { phoneNumber, name, practiceId, documentId, time, previousTime });
        
        confirmActivity(phoneNumber, name, practiceId, documentId, time, previousTime, patientId, column)
            .then(response => {
                console.log('Confirmation successful:', response);
                updateActivityStatus(activity.id, false, true, "Success");
                practiceStats.patientsReactivated += 1;
                practiceStats.totalOverdue -= 1;
                refreshNotifications(); 
            })
            .catch(error => {
                console.error('Error confirming:', error);
                updateActivityStatus(activity.id, false, true, "Failure");
                // Handle error case here
            });

        console.log('Activity object after update:', activity);
    };

    const handleManualHandling = (activity) => {

        const { name, practiceId } = activity;

        ManualHandleActivity(name, practiceId)
            .then(response => {
                console.log('Manual successful:', response);
                updateActivityStatus(activity.id, true, false, "Manual");
                refreshNotifications(); 
            })
            .catch(error => {
                console.error('Error with Manual:', error);
                // Handle error case here
            });

        console.log('Activity object after update:', activity);
        
    };

    const handleThrowClick = (document) => {
        // Placeholder for click handling logic
        console.log('Document clicked:', document);
        // You can add your logic here later
        const url = document.practiceId
        ? `/conversation/${document.PatientId}/${document.practiceId}`
        : `/conversation/${document.PatientId}`;
        console.log("url ", url)
        navigate(url);
    };

    const handleThrowDocumentResolved = (document) => {
        console.log('Resolve clicked:', document);
        
        // Call API to mark the document as resolved
        markThrowDocumentResolved(document).then(() => {
            // Update state only after the API call is successful
            setThrowDocuments(prevDocuments => prevDocuments.map(doc => {
                if (doc.id === document.id) { // Assuming each document has a unique 'id' property
                    return { ...doc, ActedOn: true }; // Mark the document as acted upon
                }
                return doc;
            }));
    
            refreshNotifications();
        }).catch(error => {
            console.error('Error marking document as resolved:', error);
            // Handle error case here, possibly setting an error state or showing a notification
        });
    };


    const formatDate = (dateStr) => {
        console.log(dateStr)
        if (!dateStr) return 'N/A'; // Handle null dates
  
        if (dateStr.length === 13 && dateStr[10] === 'T') {
          dateStr += ':00'; // Append ':00' to represent minutes
      }
  
        const date = new Date(dateStr);
        return date.toLocaleString(); // Format date to a human-readable string
    };

    const activityColumns = [
        { header: 'Patient Name', key: 'patientName' },
        { header: 'New Time', key: 'newTime' },
        { header: 'Campaign', key: 'campaign' },
        { header: 'Result', key: 'result' }
    ];

    const throwDocumentColumns = [
        { header: 'Reason', key: 'reason' },
        { header: 'Patient Name', key: 'name' },
        { header: 'Phone Number', key: 'phoneNumber' },
        { header: 'Status', key: 'status' }
    ];

    const onRowClick = (rowData) => {
        console.log("rowData", rowData)
        const url = `/conversation/${rowData.patientId}`
        navigate(url);
    };
    
    return (
        <>
            {isDemoRunning && <DemoBanner />} 
            <Header />
            <div className='flex-container'>
                <Sidebar />
                <MainContainer title="Activity">
                    <div className='activity-container'>
                    <Tabs tabs={tabs} currentView={currentView} setCurrentView={setCurrentView} />
                        {currentView === 'activities' && (
                            <>
                                {activities.filter(activity => activity.type === "SchedulingEvent" && !activity.ActedOn).map(activity => {
                                // Added:
                                const secondaryTextsForActivities = [
                                    { label: 'Current Date', value: formatDate(activity.previousTime) },
                                    { label: 'Campaign', value: 'Reactivation outreach'},
                                    { label: 'New Date', value: formatDate(activity.time) }
                                ];

                                if (practiceIdGlobal === "Admin") {
                                    secondaryTextsForActivities.push({ label: 'PracticeId', value: activity.practiceId });
                                }
                                // End Added

                                    return (
                                        <ActionCard 
                                            key={activity.id}
                                            title="Scheduling request"
                                            primaryText={{
                                                label: 'Patient',
                                                value: activity.name,
                                                actionLabel: 'Confirm Reschedule'
                                            }}
                                            // Modified:
                                            secondaryTexts={secondaryTextsForActivities}
                                            secondaryActionLabel="Handle Manually"
                                            onPrimaryAction={() => handleConfirm(activity)}
                                            onSecondaryAction={() => handleManualHandling(activity)}
                                        />
                                    );
                                })}
                                <h3 className="table-header">Booked Appointments</h3>
                                <TableComponent data={activities.filter(activity => activity.ActedOn).map(activity => ({
                                    patientName: activity.name,
                                    newTime: activity.time,
                                    campaign: practiceIdGlobal === 'Sams Practice' ? 'New patient from website' : 'Reactivation outreach',
                                    result: activity.Result || 'N/A',
                                    patientId: activity.patientId
                                }))}
                                columns={activityColumns}
                                onClick={onRowClick} 
                                />
                            </>
                        )}
    
                        {currentView === 'throwDocuments' && (
                            <>
                                <div className="action-cards-container">
                                    {throwDocuments.filter(document => !document.ActedOn).map((document, index) => {
                                        const secondaryTextsForThrowDocs = [
                                        { label: 'Name', value: document.name },
                                        { label: 'Phone Number', value: document.phoneNumber },
                                        { label: 'Status', value: document.ActedOn ? 'Handled' : 'Pending' },
                                        ];

                                        if (practiceIdGlobal === "Admin") {
                                        secondaryTextsForThrowDocs.push({ label: 'PracticeId', value: document.practiceId });
                                        }

                                        return (
                                        <ActionCard
                                            key={index}
                                            title="Conversation to Continue"
                                            primaryText={{ label: 'Reason', value: document.failReason, actionLabel: 'Continue Conversation' }}
                                            secondaryTexts={secondaryTextsForThrowDocs}
                                            secondaryActionLabel="Resolve"
                                            onPrimaryAction={() => handleThrowClick(document)}
                                            onSecondaryAction={() => handleThrowDocumentResolved(document)}
                                        />
                                        );
                                    })}
                                </div>
                                <h3 className="table-header">Handled Conversations</h3>
                                <TableComponent data={throwDocuments.filter(document => document.ActedOn)
                                    .filter(document => document.phoneNumber !== '+17816907444')
                                    .map(document => ({
                                    reason: document.failReason,
                                    name: document.name,
                                    phoneNumber: document.phoneNumber,
                                    status: 'Handled'
                                }))}
                                columns={throwDocumentColumns}
                                />
                            </>
                        )}
                    </div>
                </MainContainer>
                {practiceIdGlobal === 'Sams Practice' && <ChatWidget />} 
            </div>
        </>
    );
    

};

export default ActivityPage;

