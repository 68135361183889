import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "../ui/Dialog";
import { MapPin, DollarSign, ClipboardList, MoreHorizontal, Edit, Save } from 'lucide-react';
import { Button } from '../ui/Button';
import { getKnowledgeBase, updateKnowledgeBase } from "../services/ActivityService";

const KnowledgeBase = () => {
    const [activeItem, setActiveItem] = useState('Location and Contact Details');
    const [knowledgeBaseData, setKnowledgeBaseData] = useState(null);
    const [practiceIdGlobal, setPracticeIdGlobal] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [editedContent, setEditedContent] = useState([]);

    useEffect(() => {
        const fetchKnowledgeBase = async () => {
            const practiceId = localStorage.getItem('practiceId');
            if (practiceId) {
                setPracticeIdGlobal(practiceId);
                try {
                    const data = await getKnowledgeBase(practiceId);
                    setKnowledgeBaseData(data);
                } catch (error) {
                    console.error('Error fetching knowledge base:', error);
                }
            } else {
                console.log('No practiceId found in local storage');
            }
        };

        fetchKnowledgeBase();
    }, []);

    const handleEditClick = () => {
        setIsEditing(true);
        setEditedContent(getContentForActiveItem());
    };

    const handleContentChange = (index, newValue) => {
        const newContent = [...editedContent];
        newContent[index] = newValue;
        setEditedContent(newContent);
    };

    const handleAddNewFact = () => {
        setEditedContent([...editedContent, '']);
    };

    const handleSaveChanges = async () => {
        const activeItemContent = menuItems.find(item => item.name === activeItem)?.content;
        const updatedFacts = {
            ...knowledgeBaseData.facts,
            [activeItemContent]: editedContent.filter(item => item.trim() !== '')
        };
    
        try {
            await updateKnowledgeBase(practiceIdGlobal, updatedFacts);
            setKnowledgeBaseData({...knowledgeBaseData, facts: updatedFacts});
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating knowledge base:', error);
        }
    };

    const menuItems = [
        { name: 'Location and Contact Details', icon: <MapPin className="w-4 h-4" />, content: 'Address and Contact' },
        { name: 'Insurance and Payment', icon: <DollarSign className="w-4 h-4" />, content: 'Insurance' },
        { name: 'Services and Providers', icon: <ClipboardList className="w-4 h-4" />, content: 'Procedures and Costs' },
        { name: 'Miscellaneous', icon: <MoreHorizontal className="w-4 h-4" />, content: 'Policies and Misc' },
    ];

    if (!knowledgeBaseData) {
        return <div className="flex items-center justify-center h-full">Loading...</div>;
    }

    const getContentForActiveItem = () => {
        const activeItemContent = menuItems.find(item => item.name === activeItem)?.content;
        return knowledgeBaseData.facts[activeItemContent] || [];
    };


    // ... (previous state and functions remain unchanged)

    return (
        <DialogContent className="!w-[900px] !h-[80vh] !max-h-[700px] !translate-x-[-50%] !translate-y-[-50%] !p-0 flex flex-col overflow-hidden">
            <DialogHeader className="flex-shrink-0 p-6 pb-2">
                <DialogTitle>Knowledge Base for {practiceIdGlobal}</DialogTitle>
            </DialogHeader>
            <div className="flex flex-1 overflow-hidden p-6 pt-2">
                <div className="w-1/3 pr-4 flex flex-col border-r overflow-hidden">
                    <nav className="flex-1 overflow-y-auto pr-2">
                        {menuItems.map((item) => (
                            <button
                                key={item.name}
                                onClick={() => {
                                    if (isEditing && activeItem !== item.name) {
                                        if (window.confirm('Discard unsaved changes?')) {
                                            setActiveItem(item.name);
                                            setIsEditing(false);
                                            setEditedContent(knowledgeBaseData.facts[item.content] || []);
                                        }
                                    } else {
                                        setActiveItem(item.name);
                                        if (!isEditing) {
                                            setEditedContent(knowledgeBaseData.facts[item.content] || []);
                                        }
                                    }
                                }}
                                className={`flex items-center gap-3 rounded-lg px-3 py-2 text-sm font-medium transition-all hover:bg-muted hover:text-primary w-full mb-2 ${
                                    activeItem === item.name ? 'bg-muted text-primary' : 'text-muted-foreground'
                                }`}
                            >
                                {item.icon}
                                {item.name}
                            </button>
                        ))}
                    </nav>
                    <Button 
                        onClick={isEditing ? handleSaveChanges : handleEditClick}
                        className="flex items-center justify-center gap-2 mt-4 w-full"
                    >
                        {isEditing ? <Save className="w-4 h-4" /> : <Edit className="w-4 h-4" />}
                        {isEditing ? 'Save Changes' : 'Edit'}
                    </Button>
                </div>
                <div className="flex-1 pl-4 overflow-hidden flex flex-col">
                    <h3 className="text-lg font-semibold mb-4">{activeItem}</h3>
                    <div className="flex-1 overflow-y-auto pr-2">
                        {isEditing ? (
                            <div className="space-y-4">
                                {editedContent.map((item, index) => (
                                    <div key={index} className="mb-2">
                                        <textarea
                                            value={item}
                                            onChange={(e) => handleContentChange(index, e.target.value)}
                                            className="w-full p-2 border rounded"
                                            rows="3"
                                        />
                                    </div>
                                ))}
                                <Button onClick={handleAddNewFact} variant="outline" className="mt-4">
                                    Add New Fact
                                </Button>
                            </div>
                        ) : (
                            <ul className="space-y-2">
                                {getContentForActiveItem().map((item, index) => (
                                    <li key={index} className="flex items-start">
                                        <span className="mr-2">•</span>
                                        <span>{item}</span>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
        </DialogContent>
    );
};

export default KnowledgeBase;