import React from 'react';
import './AuthContainer.css'; // Assuming you have a separate CSS file for styling

// Define the type of props expected using TypeScript or PropTypes for runtime type-checking (optional)
// TypeScript example:
/*
type AuthContainerProps = {
  title: string;
  children: React.ReactNode;
};
*/

// PropTypes example:
/*
AuthContainer.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
*/

const AuthContainer = ({ title, children }) => {
  return (
    <div className="auth-container">
      <h2 className="auth-title">{title}</h2>
      <div className="auth-content">
        {children}
      </div>
    </div>
  );
};

export default AuthContainer;
