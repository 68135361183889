// AuthLayout.js

import React from 'react';
import './AuthLayout.css'; // Create this CSS file for AuthLayout styles

const AuthLayout = ({ children }) => {
    return <div className="auth-layout">{children}</div>;
};

export default AuthLayout;
