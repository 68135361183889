import React, { useState } from 'react';
import './MessageContainer.css'
import { sendMessage } from '../services/patientsService'; // Adjust the path as necessary

// import { getConversationMessages } from '../services/conversationService';

const MessageContainer = ({ practiceId, messages, phoneNumber, useTestData = false }) => {
  // No need to use useState here as messages are passed as a prop

  const [inputMessage, setInputMessage] = useState('');

  const handleInputChange = (e) => {
    setInputMessage(e.target.value);
    console.log('Input Value: ', e.target.value); // Log the current input

  };

  console.log("phoneNumnber23 ", phoneNumber);
  console.log("practiceId ", practiceId);

  function swapSender(sender)
  {
    if(sender === "system")
    {
      return "user"
    }
    return "system"
  }

  const handleSendMessage = () => {
    // Confirmation step
    const isConfirmed = window.confirm(`Are you sure you want to send "${inputMessage}" to ${phoneNumber}?`);
    if (isConfirmed) {
      sendMessage(inputMessage, practiceId, phoneNumber); // Call the service function
      setInputMessage(''); // Clear the input after sending
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default enter key behavior
      handleSendMessage(); // Use the same function for sending the message
    }
  };

  const testData = [
    { sender: 'practice', message: "Hey Henry - Jim from Smiles Dental here. You're due for a cleaning and we have an opening next Wed at 12pm. Can you make it?" },
    { sender: 'patient', message: "I can't make that, but do you have anything open next Thursday in the morning?" },
    { sender: 'practice', message: 'Yes! We have an open slot at 10 am. Would you like to take it?' },
    { sender: 'patient', message: "Yup, sounds great. Let's lock it in" },
    { sender: 'practice', message: "Ok great, let me confirm with my team" },
    { sender: 'practice', message: "You're all set! See you next week" },
  ];

  console.log('Received messages in MessageContainer:', messages);

  const actualMessages = useTestData ? testData : (messages.length > 0 ? messages : []);

  console.log('this is actualMessages:', actualMessages);

 

  return (
    <div className='message-container'>
      {actualMessages.map((message, index) => (
        <div key={index} className={`message ${practiceId.startsWith("tester") ? swapSender(message.sender) : message.sender}`}>
          <div> {message.message} </div>
        </div>
      ))}

    <div className="input-container"> 
      <input
        type="text"
        value={inputMessage}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type your message here..."
        className='message-input'
      />
      <button onClick={handleSendMessage} className='send-button'>Send</button>
    </div>
    </div>
);
};

export default MessageContainer;
