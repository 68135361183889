import React, { useState, useEffect } from 'react';
import { Button } from '../ui/Button';
import ReactivationViewEdit from './ReactivationViewEdit';
import { fetchReactivationSettings } from '../services/userInfoService'; // Adjust the import path as needed

const ReactivationView = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const practiceId = 'Sams Practice';

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await fetchReactivationSettings(practiceId);
        setData({
          outreachPerDay: settings.outreach.perDay,
          outreachHours: settings.outreach.hours,
          sequence: settings.sequence
        });
        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching reactivation settings:', err);
        setError('Failed to load reactivation settings');
        setIsLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const formatOutreachHours = (hours) => {
    const days = Object.keys(hours).filter(day => hours[day] !== null);

    if (days.length === 0) {
      return 'Closed all days'; // Handle case where no days are specified
    }

    // Handle single day separately to avoid repetition
    if (days.length === 1) {
      const day = days[0];
      return `${day} ${hours[day].start}-${hours[day].end}`;
    }

    // Format string for multiple days
    // This can be refined to group consecutive days and list non-consecutive days separately
    return `${days[0]}-${days[days.length-1]} ${hours[days[0]].start}-${hours[days[0]].end}`;
  };

  const handleSave = (newData) => {
    setData(newData);
    setIsEditing(false);
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!data) return <div>No data available</div>;

  if (isEditing) {
    return <ReactivationViewEdit onSave={handleSave} initialData={data} practiceId={practiceId}/>;
  }

  return (
    <div className="p-4 h-full overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">Reactivation</h2>
        <Button onClick={() => setIsEditing(true)}>Edit</Button>
      </div>
      <div className="mb-4 text-sm">
        <p><strong>Outreach per day:</strong> {data.outreachPerDay}</p>
        <p><strong>Outreach hours:</strong> {formatOutreachHours(data.outreachHours)}</p>
      </div>
      <div>
        <h3 className="text-lg font-semibold mb-2">Sequence</h3>
        <div className="flex flex-col items-start space-y-2">
          {data.sequence.map((item, index) => (
            <React.Fragment key={index}>
              <div className="flex items-center w-full">
                <div className="border rounded p-2 w-32 text-center text-sm">{item.message}</div>
                <div className="ml-2 text-xs text-gray-500">
                  {index === 0 ? 'Start' : `${item.buffer} days later`}
                </div>
              </div>
              {index < data.sequence.length - 1 && (
                <div className="h-6 border-l-2 border-gray-300 ml-16"></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReactivationView;