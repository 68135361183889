import React, { useState, useEffect } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/Select';
import { Shield } from 'lucide-react';

const AdminPracticeSelector = ({ currentPracticeId, onPracticeChange }) => {
    const [practices, setPractices] = useState([
      { id: 'Smiles Dental Admin', name: 'Acevedo Dental Admin', location: 'n/a' },
      { id: 'SmilesDental1', name: 'Acevedo Dental Santa Ana', location: 'Santa Ana, California, USA' },
      { id: 'SmilesDental2', name: 'Acevedo Dental Montebello', location: 'Montebello, California, USA' },
      { id: 'acevedo', name: 'Acevedo Dental Ontario', location: 'Ontario, California, USA' }]);
  
    const currentPractice = practices.find(p => p.id === currentPracticeId) || practices[0];
  
    return (
      <div className="flex items-center gap-2">
        <Shield className="h-5 w-5 text-blue-500" title="Admin Portal" />
        <Select onValueChange={onPracticeChange} defaultValue={currentPracticeId}>
          <SelectTrigger className="border-none bg-transparent p-0">
            <SelectValue>
              <div className="flex flex-col justify-center">
                <span className="text-lg font-semibold leading-tight">{currentPractice.name}</span>
                <span className="text-sm text-muted-foreground leading-tight">{currentPractice.location}</span>
              </div>
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {practices.map((practice) => (
              <SelectItem key={practice.id} value={practice.id}>
                <div className="flex flex-col">
                  <span className="font-semibold">{practice.name}</span>
                  <span className="text-sm text-muted-foreground">{practice.location}</span>
                </div>
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };
  
  export default AdminPracticeSelector;
  