
import React from 'react';
import './ProgressTracker.css'; // Assuming you have the CSS in a separate file

const ProgressTracker = ({ blackLinesCount }) => {
  const lineColors = ['ccc', 'ccc', 'ccc']; // Default colors

  for (let i = 0; i < blackLinesCount; i++) {
    lineColors[i] = '#3873FF';
  }

  return (
    <div className="progress-tracker">
      {lineColors.map((color, index) => (
        <div key={index} className="divider" style={{ backgroundColor: color }}></div>
      ))}
    </div>
  );
}

export default ProgressTracker;

