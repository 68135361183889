import React, { useState } from 'react';
import AuthContainer from '../components/AuthContainer'; // Adjust the path as needed
import FormInput from '../components/FormInput';
import './RegistrationPage.css'; // Your specific styles for the registration form
import FormButton from '../components/FormButton';
import logo from '../assets/logo.png';
import { signUp } from '../services/supabaseClient';
import AuthLayout from '../components/AuthLayout';
import { useNavigate } from 'react-router-dom'; 
import { getUserDetails} from '../services/userInfoService';
import { checkCode } from '../services/userInfoService';


const RegistrationPage = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate(); // Initialize useNavigate hook
    const demoIds = ["VarinosDemo", "ItaniDemo", "WaitlistDemo", "Jemin", "Sams Practice", "starBrite", "Southern Op", "MissionFremont", "IAS", "NaturalSmiles", "wynn"]

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevent the form from being submitted traditionally
        setLoading(true);
        setError('');
        try {

          // check to see if the code is valid 
          const practiceId = await checkCode(code);
          console.log("practiceIdFromCode ", practiceId)
          if (!practiceId)
          {
            // code is not valid, abort process
            setError("code is not valid");
            setEmail("");
            setPassword("");
            setCode("");
            return;
          }

          const { data, error } = await signUp(email, password, practiceId);
          console.log("data", data)
          if (error) throw error;
          // Fetch user details here
        getUserDetails(email)
        .then(details => {
            console.log('User Details:', details);
            const practiceId = details.practiceId; // Adjust this according to how you receive practiceId
            localStorage.setItem('practiceId', practiceId);
            // Navigate after storing the practiceId
            if(true)
            {
                navigate('/home');
            }
            else
            {
                navigate('/schedule');
            }      
        })
        .catch(error => {
            console.error('Error fetching user details:', error);
            // Handle errors related to fetching user details
            // Optionally, you can still navigate to '/schedule' even if there's an error fetching details
            navigate('/schedule');
        });
        } catch (error) {
          setError(error.error_description || error.message);
        } finally {
          setLoading(false); // Ensure loading is false after the operation
        }
      };

  return (
    <AuthLayout>

    <img src={logo} alt="Company Logo" className="company-logo" />

    <AuthContainer title ="Create an account">
    <form onSubmit={handleSubmit}>
          <FormInput
            type="email"
            label="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormInput
            type="password"
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormInput
            type="code"
            label="Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          {error && <p className="error">{error}</p>}
          <FormButton
            label={'Register'}
            disabled={loading}
          />
          <p>
            Already have an account? <a href="/login">Log in</a>
          </p>
    </form>
      
    </AuthContainer>
    </AuthLayout> 
  );
};

export default RegistrationPage;

