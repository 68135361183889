import React, { useState, useEffect } from 'react';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table"
import { fetchCallData } from '../services/userInfoService';
import { useNavigate } from 'react-router-dom';

const CallLog = () => {
  const [callData, setCallData] = useState([]);
  const [globalPracticeId, setGlobalPracticeId] = useState('')
  const navigate = useNavigate();

  useEffect(() => {
    const practiceId = localStorage.getItem('practiceId') || '';
    if (!practiceId) {
      console.log('No practiceId found in local storage');
      navigate('/');
      return;
    }
    setGlobalPracticeId(practiceId);

    const fetchCalls = async () => {
      try {
        console.log("fetchCalls ", globalPracticeId)
        const data = await fetchCallData(globalPracticeId);
        setCallData(data);
        console.log("Fetched call data:", data);
      } catch (error) {
        console.error('Error fetching call data:', error);
      }
    };

    fetchCalls(); // Initial fetch

    // Set up interval to fetch data every 5 seconds
    const intervalId = setInterval(fetchCalls, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(intervalId);
  }, [globalPracticeId, navigate]);

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
       <Sidebar practiceId={globalPracticeId}/>
      </div>

      <div className="flex flex-col min-h-screen">
        <MainContent title="After Hours Call Log">
          <Table>
            <TableCaption>After Hours Call Log</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Number</TableHead>
                <TableHead>Name</TableHead>
                <TableHead>Summary</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {callData.map(call => (
                <TableRow key={call.id}>
                  <TableCell>{call.phoneNumber}</TableCell>
                  <TableCell>{call.name}</TableCell>
                  <TableCell>{call.details}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </MainContent>
      </div>
    </div>
  );
};

export default CallLog;