import React, {useState} from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription, DialogFooter, DialogClose, DialogTrigger} from './Dialog';
import { Calendar, CreditCard} from 'lucide-react';
import { ComboboxDemo } from './Combobox';
import { Input } from './Input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./Select"

function SettingsDialog({ isOpen, onClose }) {
  // Function to close the dialog
  const handleClose = () => {
    onClose();
  };

  const [activePage, setActivePage] = useState('pms');

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>

        <DialogContent className="sm:max-w-[750px] h-[300px]">
          <DialogHeader>

            <DialogTitle>Settings</DialogTitle>

          </DialogHeader>

                <div className="grid grid-cols-3 gap-2">
                  {/* Sidebar */}
                  <div className="col-span-1 ">
                      <button
                        className={`block w-full py-2 px-4 text-left text-sm font-medium hover:bg-gray-100 ${activePage === 'pms' ? 'bg-gray-300' : ''}`}
                        onClick={() => handlePageChange('pms')}
                      >
                        <Calendar className="inline-block w-4 h-4 mr-2" />
                        Practice Management
                      </button>
                      <button
                        className={`block w-full py-2 px-4 text-left text-sm font-medium hover:bg-gray-100 ${activePage === 'payment' ? 'bg-gray-300' : ''}`}
                        onClick={() => handlePageChange('payment')}
                      >
                        <CreditCard className="inline-block w-4 h-4 mr-2" />
                        Payment Details
                      </button>
                  </div>
                  {/* Main content */}
                  <div className="col-span-2 p-4">

                                {activePage === 'pms' && (
                              <div>


                                <Select className="mb-4">
                                  <SelectTrigger className="w-[180px]">
                                    <SelectValue placeholder="Select your PMS" />
                                  </SelectTrigger>
                                  <SelectContent>
                                    <SelectItem value="dentrix">Dentrix</SelectItem>
                                    <SelectItem value="eaglesoft">Eaglesoft</SelectItem>
                                    <SelectItem value="opendental">Open Dental</SelectItem>
                                  </SelectContent>
                                </Select>



                                

                                <Input className="mb-4" type="email" placeholder="Email" />


                                <Dialog>
                                  <DialogTrigger>Launch</DialogTrigger>
                                  <DialogContent>
                                    <DialogHeader>
                                      <DialogTitle>Connect to your PMS</DialogTitle>
                                      <DialogDescription>

                                        Hemingway uses Kolla to connect your PMS. Kolla's HIPAA compliant integration allows you to connect to your data in seconds. 
                                        <div> <a href='https://link.kolla.market/link/a74cmpceezanjgrvmd6wuagtsu' class="text-blue-500 underline hover:text-blue-700">Click here to connect</a></div>
                                        
                  
                                      </DialogDescription>
                                    </DialogHeader>
                                  </DialogContent>
                                </Dialog>

                                
                </div>
                            )}
                            {activePage === 'payment' && (
                              <div>
                              Payment Page
                              </div>
                            )}
                  </div>
                </div>


        </DialogContent>

    </Dialog>
  );
}

export default SettingsDialog;
