import React, { useContext, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import { NotificationContext } from '../NotificationContext';

const productionPracticeIds = ['Jemin', 'Chua', 'wynn', "starBrite", "Jemin2", "GraceDental", "Demo1", "Admin", "IAS", "nkc1", "starBriteFremont"];

const Sidebar = () => {
    const { notificationCount } = useContext(NotificationContext);
    const [showSettings, setShowSettings] = useState(false);
    const [showPatients, setShowPatients] = useState(true);
    const navigate = useNavigate();
    const [showDemo, setShowDemo] = useState(true);
    const [showDemo2, setShowDemo2] = useState(false);
    const [showConfirm, setshowConfirm] = useState(false);
    const [showDashboard, setShowDashboard] = useState(false);
    const [showPatsAndCal, setshowPatsAndCal] = useState(true);

    useEffect(() => {
        const id = localStorage.getItem('practiceId');
        if (id && productionPracticeIds.includes(id)) { // Check if the practiceId is in the production list
            setShowSettings(false);
            if(id !== "Demo1")
            {
                setShowDemo(false); // Do not show Run Demo for production IDs
            }
        } else if (id) { // Checking if the practiceId is exactly "den"
            setShowSettings(true);
        } else if (!id) { // If there's no practiceId at all
            console.log('No practiceId found in local storage');
            navigate('/');
        }

        console.log("what is going on ", id)

        if(id && ["eastlink", "investorDemo", "investorJeff", "investor10 Dental", "investor11 Dental", "investor12 Dental", "investor13 Dental", "investor14 Dental", "voiceTester"].includes(id))
        {
            console.log("test2")
            setShowSettings(false);
            setShowPatients(false);
            setShowDemo2(true);
        }

        if(id && id === "Jemin")
        {
            setshowConfirm(true);
        }

        if(id && id === "Admin")
        {
            setshowPatsAndCal(false);
            setShowDashboard(true)
        }

    }, [navigate]);

    return (
        <nav className="sidebar">
            <ul className="nav-list">
                {showDashboard && (
                    <li className="nav-item">
                        <NavLink to="/dashboard" activeClassName="home" className="nav-link">
                            <span className="material-icons">dashboard</span>
                            dashboard
                        </NavLink>
                    </li>
                )
                }
                <li className="nav-item">
                    <NavLink to="/activity" activeClassName="active" className="nav-link">
                            <div style={{ position: 'relative' }}> {/* Container for positioning */}
                                <span className="material-icons">notifications</span>
                                Activity
                                {notificationCount > 0 && <span className="notification-badge">{notificationCount}</span>}
                            </div>
                    </NavLink>
                </li>
                {showConfirm && (
                    <li className="nav-item">
                    <NavLink to="/confirmations" activeClassName="active" className="nav-link">
                        <span className="material-icons">calendar_today</span>
                        Confirmations
                    </NavLink>
                </li>
                )}
                {showPatsAndCal && (
                    <li className="nav-item">
                    <NavLink to="/schedule" activeClassName="active" className="nav-link">
                        <span className="material-icons">calendar_today</span>
                        Schedule
                    </NavLink>
                    </li>
                )}
                {showPatsAndCal && showPatients && (
                    <li className="nav-item">
                    <NavLink to="/patients" activeClassName="active" className="nav-link">
                        <span className="material-icons">people</span>
                        Patients
                    </NavLink>
                </li>
                )}
                <li className="nav-item">
                    <NavLink to="/messages" activeClassName="active" className="nav-link">
                        <span className="material-icons">message</span>
                        Messages
                    </NavLink>
                </li>
                {showSettings && (
                    <li className="nav-item">
                        <NavLink to="/settings" activeClassName="active" className="nav-link">
                            <span className="material-icons">settings</span>
                            Settings
                        </NavLink>
                    </li>
                )}
                {showDemo && (
                    <li className="nav-item">
                        <NavLink to="/rundemo" activeClassName="active" className="nav-link">
                            <span className="material-icons">arrow_forward</span>
                            Run Demo
                        </NavLink>
                    </li>
                )}
                {showDemo2 && (
                    <li className="nav-item">
                        <NavLink to="/VoiceDemoPage" activeClassName="active" className="nav-link">
                            <span className="material-icons">arrow_forward</span>
                            Run Voice Demo
                        </NavLink>
                    </li>
                )}
                {/* More links can be added here */}
            </ul>
        </nav>
    );
};

export default Sidebar;