import React from 'react';
import { Badge } from '../ui/Badge';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../ui/Card";

const ChatSidebar = ({ conversations, onConversationClick, searchTerm, setSearchTerm }) => {
  console.log("conversations sidebar ", conversations)
  return (
    <div className="px-4">
      <input
        type="text"
        placeholder="Search conversations..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="w-full mb-4 px-2 py-1 border border-gray-300 rounded"
      />
      {conversations && conversations.map((conversation) => (
        <Card
          key={conversation.id}
          className="h-15 mb-2 cursor-pointer"
          onClick={() => onConversationClick(conversation)}
        >
          <CardHeader>
            <CardTitle>{conversation.name}</CardTitle>
            {/* add in summary when available */}
            <CardDescription></CardDescription> 
          </CardHeader>
        </Card>
      ))}
    </div>
  );
};

export default ChatSidebar;