import React, { useState, useEffect } from 'react';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";
import { getConversationsByPractice } from '../services/messagesService';
import { useNavigate } from 'react-router-dom';

const Conversations = () => {
  const [conversations, setConversations] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [globalPracticeId, setGlobalPracticeId] = useState('');

  useEffect(() => {
    const practiceId = localStorage.getItem('practiceId') || '';
    if (!practiceId) {
      console.log('No practiceId found in local storage');
      navigate('/');
      return;
    }

    setGlobalPracticeId(practiceId)

    var practiceIdForActivities = practiceId;
    if (practiceId === "Admin") {
      practiceIdForActivities = ['Jemin', 'Chua', 'wynn', "starBrite", "Jemin2", "GraceDental", "IAS", "nkc1", "starBriteFremont"];
    }

    getConversationsByPractice(practiceIdForActivities, 1, 10, '')
      .then(data => {
        const modifiedConversations = data.map(convo => ({
          id: convo.id || '',
          timeStarted: convo.timeStarted || 'n/a',
          summary: convo.summary || 'n/a',
          type: convo.type || 'Text',
          patientName: convo.name || '',
          nextAppointment: convo.nextAppointment || 'n/a',
          phoneNumber: convo.phoneNumber
        }));

        setConversations(modifiedConversations);
      })
      .catch(error => setError(error));
  }, [navigate]);

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleRowClick = (convo) => {
    navigate(`/conversation_/${convo.phoneNumber}`,{
      state: {
        patientName: convo.patientName,
        // Add any other relevant data here
      }
    });
  };

  return (
    <div className="grid h-screen w-screen md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <Sidebar practiceId={globalPracticeId}/>
      </div>
      <div className="flex flex-col min-h-screen">
        <MainContent title="Conversations">
          <Table>
            <TableCaption>Conversations</TableCaption>
            <TableHeader>
              <TableRow>
                {/*<TableHead>Time Started</TableHead>
                <TableHead>Summary</TableHead> */}
                <TableHead>Type</TableHead> 
                <TableHead>Patient Name</TableHead>
                {/* <TableHead>Next Appointment</TableHead> */}
              </TableRow>
            </TableHeader>
            <TableBody>
              {conversations.map((convo, index) => (
                <TableRow 
                key={index} 
                onClick={() => handleRowClick(convo)}
                className="cursor-pointer"
                >
                  {/* <TableCell>{convo.timeStarted}</TableCell> */}
                   {/* <TableCell>{convo.summary}</TableCell> */}
                  <TableCell>
                    <Badge>{convo.type}</Badge>
                  </TableCell>
                  <TableCell>{convo.patientName}</TableCell>
                  {/* <TableCell>{convo.nextAppointment}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </MainContent>
      </div>
    </div>
  );
};

export default Conversations;