import React, { useState } from 'react';
import { Button } from '../ui/Button';
import { Input } from '../ui/Input';
import { Textarea } from '../ui/TextArea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/Select';
import { updateReactivationSettings } from '../services/userInfoService';  // Adjust the import path as needed
import OutreachHours from './OutReachHours'; 

const AVAILABLE_VARIABLES = ['patientName', 'timeSinceLastVisit', 'nextAvailableOpening'];

const ReactivationEditView = ({ onSave, initialData, practiceId }) => {

    console.log("initialData ", initialData)
    const [formData, setFormData] = useState(initialData || {
        outreachPerDay: 50,
        outreachHours: {
            monday: { start: '09:00', end: '18:00' },
            tuesday: { start: '09:00', end: '18:00' },
            wednesday: { start: '09:00', end: '18:00' },
            thursday: { start: '09:00', end: '18:00' },
            friday: { start: '09:00', end: '18:00' },
            saturday: { start: '', end: '' },
            sunday: { start: '', end: '' }
        },
        sequence: [
        { channel: 'text', buffer: 4, message: 'Hi {patientName} - this is Colleen from Iverson and Syers. I noticed it\'s been over {timeSinceLastVisit} since your last visit. We have openings this week and next. How is {nextAvailableOpening}?' }
        ]
    });

  const handleInputChange = (e, index, field) => {
    if (index !== undefined) {
      const newSequence = [...formData.sequence];
      newSequence[index] = { ...newSequence[index], [field]: e.target.value };
      setFormData({ ...formData, sequence: newSequence });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const addNewConversation = () => {
    setFormData({
      ...formData,
      sequence: [...formData.sequence, { channel: '', buffer: '', message: '' }]
    });
  };

  const deleteConversation = (index) => {
    const newSequence = formData.sequence.filter((_, i) => i !== index);
    setFormData({ ...formData, sequence: newSequence });
  };

  const insertVariable = (index, variable) => {
    const newSequence = [...formData.sequence];
    const currentMessage = newSequence[index].message;
    const updatedMessage = currentMessage + `{${variable}}`;
    newSequence[index] = { ...newSequence[index], message: updatedMessage };
    setFormData({ ...formData, sequence: newSequence });
  };

  const handleSave = async () => {
    try {
      const formattedOutreachHours = {};
      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      
      days.forEach(day => {
        if (formData.outreachHours[day] && formData.outreachHours[day].start && formData.outreachHours[day].end) {
          formattedOutreachHours[day] = {
            start: formData.outreachHours[day].start,
            end: formData.outreachHours[day].end
          };
        }
      });
  
      const apiData = {
        outreach: {
          perDay: parseInt(formData.outreachPerDay),
          hours: formattedOutreachHours
        },
        sequence: formData.sequence.map(item => ({
          channel: item.channel,
          buffer: parseInt(item.buffer),
          message: item.message
        }))
      };
  
      await updateReactivationSettings(practiceId, apiData);
      onSave(apiData);
    } catch (error) {
      console.error('Error updating reactivation settings:', error);
      // Optionally, add error handling here, such as displaying an error message to the user
    }
  };

  const handleOutreachHoursChange = (day, value) => {
    setFormData(prev => ({
      ...prev,
      outreachHours: {
        ...prev.outreachHours,
        [day]: value
      }
    }));
  };


  return (
    <div className="p-4 h-full overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Reactivation</h2>
        <Button onClick={handleSave}>Save</Button>
      </div>
      <div className="space-y-4">
        <div>
          <label htmlFor="outreachPerDay" className="block text-sm font-medium">Outreach per day</label>
          <Input
            id="outreachPerDay"
            name="outreachPerDay"
            value={formData.outreachPerDay}
            onChange={handleInputChange}
            className="mt-1"
          />
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Outreach Hours</h3>
          <OutreachHours 
            hours={formData.outreachHours} 
            onChange={handleOutreachHoursChange}
          />
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">Sequence</h3>
          {formData.sequence.map((item, index) => (
            <div key={index} className="mb-4 p-4 border rounded">
              <h4 className="font-medium mb-2">{index === 0 ? 'First Conversation' : `Conversation ${index + 1}`}</h4>
              <div className="space-y-2">
                <div>
                  <label className="block text-sm">Channel</label>
                  <Input
                    value={item.channel}
                    onChange={(e) => handleInputChange(e, index, 'channel')}
                    className="mt-1"
                  />
                </div>
                <div>
                  <label className="block text-sm">Buffer</label>
                  <div className="flex items-center">
                    <Input
                      value={item.buffer}
                      onChange={(e) => handleInputChange(e, index, 'buffer')}
                      className="mt-1 w-20"
                    />
                    <span className="ml-2">days</span>
                  </div>
                </div>
                <div>
                  <label className="block text-sm">Message</label>
                  <Textarea
                    value={item.message}
                    onChange={(e) => handleInputChange(e, index, 'message')}
                    className="mt-1"
                    rows={4}
                  />
                  <div className="mt-2">
                    <Select onValueChange={(value) => insertVariable(index, value)}>
                      <SelectTrigger className="w-[180px]">
                        <SelectValue placeholder="Insert variable" />
                      </SelectTrigger>
                      <SelectContent>
                        {AVAILABLE_VARIABLES.map(variable => (
                          <SelectItem key={variable} value={variable}>{variable}</SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
              </div>
              {index > 0 && (
                <Button variant="destructive" onClick={() => deleteConversation(index)} className="mt-2">
                  Delete
                </Button>
              )}
            </div>
          ))}
          <Button onClick={addNewConversation}>Add new</Button>
        </div>
      </div>
    </div>
  );
};

export default ReactivationEditView;