import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "../ui/Dialog"
import { Button } from '../ui/Button';
import { Undo2, Undo2Icon, UndoIcon, UserRoundPlus, PlusCircle } from 'lucide-react';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "../ui/Table"
import { Input } from '../ui/Input';
import { fetchFormData, updateFormData } from '../services/userInfoService';

const Form = ({ title, subtitle, children }) => {
    const [formData, setFormData] = useState(null);
    const [editableFormData, setEditableFormData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const getFormData = async () => {
            try {
                const practiceId = 'Jemin';
                const type = 'GeneralInfo';
                const data = await fetchFormData(practiceId, type);
                setFormData(data);
                setEditableFormData(JSON.parse(JSON.stringify(data))); // Create a deep copy for editing
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        getFormData();
    }, []);

    const handleInputChange = (sectionIndex, fieldIndex, key, value) => {
        const newFormData = { ...editableFormData };
        newFormData.formSections[sectionIndex].fields[fieldIndex][key] = value;
        setEditableFormData(newFormData);
    };

    const addNewQuestion = (sectionIndex) => {
        const newFormData = { ...editableFormData };
        newFormData.formSections[sectionIndex].fields.push({
            fieldName: `newField${Date.now()}`, // Unique identifier
            label: "New Question",
            fieldType: "text",
            required: false
        });
        setEditableFormData(newFormData);
    };

    const saveChanges = async () => {
        try {
            const practiceId = 'Jemin';
            const type = 'GeneralInfo';
            const updatedData = await updateFormData(practiceId, type, editableFormData);
            setFormData(updatedData);
            setEditableFormData(JSON.parse(JSON.stringify(updatedData)));
            setIsEditing(false);
        } catch (error) {
            console.error('Error saving form changes:', error);
            // You might want to set an error state here and display it to the user
        }
    };

    if (loading) return <DialogContent>Loading...</DialogContent>;
    if (error) return <DialogContent>Error: {error}</DialogContent>;
    if (!formData) return <DialogContent>No form data available</DialogContent>;

    const dataToRender = isEditing ? editableFormData : formData;

    return (
        <DialogContent className="h-96 overflow-y-auto">
            <DialogHeader>
                <DialogTitle>{dataToRender.formTitle || "General Info"}</DialogTitle>
                <Button onClick={() => setIsEditing(!isEditing)}>
                    {isEditing ? "Cancel Editing" : "Edit Form"}
                </Button>
                {isEditing && <Button onClick={saveChanges}>Save Changes</Button>}
            </DialogHeader>
            {dataToRender.formSections.map((section, sectionIndex) => (
                <div key={sectionIndex}>
                    <h3 className="text-lg font-semibold mt-4 mb-2">{section.sectionName}</h3>
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead>Question</TableHead>
                                <TableHead>Response format</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {section.fields.map((field, fieldIndex) => (
                                <TableRow key={fieldIndex}>
                                    <TableCell>
                                        <Input 
                                            value={field.label}
                                            readOnly={!isEditing}
                                            onChange={(e) => handleInputChange(sectionIndex, fieldIndex, 'label', e.target.value)}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Input 
                                            value={field.fieldType === 'text' ? 'Patient\'s response' : field.options?.join(', ')}
                                            readOnly={!isEditing}
                                            onChange={(e) => handleInputChange(sectionIndex, fieldIndex, 'fieldType', e.target.value)}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {isEditing && (
                        <Button onClick={() => addNewQuestion(sectionIndex)} className="mt-2">
                            <PlusCircle className="mr-2 h-4 w-4" /> Add Question
                        </Button>
                    )}
                </div>
            ))}
        </DialogContent>
    );
};

export default Form;