import React, { useState, useEffect } from 'react';
import { Book } from 'lucide-react';
import InfoPanel from '../ui/InfoPanel';
// import { getHelpCenter } from "../services/HelpCenterService";

const HelpCenter = () => {
    const [helpCenterData, setHelpCenterData] = useState(null);

    useEffect(() => {
        // Commented out the original fetchHelpCenter function
        /*
        const fetchHelpCenter = async () => {
            try {
                const data = await getHelpCenter();
                setHelpCenterData(data);
            } catch (error) {
                console.error('Error fetching help center data:', error);
            }
        };

        fetchHelpCenter();
        */

        // Instead, set some mock data
        setHelpCenterData({
            GettingStarted: [
                "Welcome to our application!",
                "Here's how to set up your account...",
                "You can perform these key actions...",
                "If you need help, contact our support team."
            ]
        });
    }, []);

    const menuItems = [
        { name: 'Getting Started', icon: <Book className="w-4 h-4" />, content: 'GettingStarted' },
    ];

    if (!helpCenterData) {
        return <div className="flex items-center justify-center h-full">Loading...</div>;
    }

    return (
        <InfoPanel
            title="Help Center"
            menuItems={menuItems}
            contentData={helpCenterData}
        />
    );
};

export default HelpCenter;