import React from 'react';
import './TableComponent.css'; // Ensure the CSS file exists

const TableComponent = ({ data, columns, onClick }) => {

    const formatDate = (dateStr) => {
        console.log(dateStr)
        if (!dateStr || dateStr === 'N/A') return 'N/A'; // Handle null or 'N/A' dates

        if (dateStr.length === 13 && dateStr[10] === 'T') {
            dateStr += ':00'; // Append ':00' if necessary, to handle specific date format
        }

        const date = new Date(dateStr);
        return date.toLocaleString(); // Format date to a human-readable string
    };

    const renderCell = (item, column) => {
        if (column.key === 'newTime' && item[column.key]) { // Check if the column key is for date to format
            return formatDate(item[column.key]);
        }
        console.log("item: ", item)
        console.log("column.key: ", column.key)
        return item[column.key] || 'N/A'; // Return 'N/A' if the value is falsy
    };

    const handleRowClick = (item) => {
        if (onClick) onClick(item); // Only invoke onClick if it's provided
    };

    return (
        <div className="table-container">
            <table>
                <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}>{column.header}</th> // Use column.header for the column name
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} onClick={() => handleRowClick(item)}>
                            {columns.map((column, colIndex) => (
                                <td key={colIndex}>{renderCell(item, column)}</td> // Render cell using renderCell function
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default TableComponent;
