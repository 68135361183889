import React from 'react';
import { Input } from '../ui/Input';

const DAYS_OF_WEEK = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

const OutreachHours = ({ hours, onChange }) => {
  const handleTimeChange = (day, type, value) => {
    onChange(day.toLowerCase(), {
      ...(hours[day.toLowerCase()] || {}),
      [type]: value
    });
  };

  return (
    <div className="space-y-2">
      {DAYS_OF_WEEK.map((day) => (
        <div key={day} className="flex items-center space-x-4">
          <span className="w-24">{day}</span>
          <Input
            type="time"
            value={hours[day.toLowerCase()]?.start || ''}
            onChange={(e) => handleTimeChange(day, 'start', e.target.value)}
            className="w-32"
          />
          <span>to</span>
          <Input
            type="time"
            value={hours[day.toLowerCase()]?.end || ''}
            onChange={(e) => handleTimeChange(day, 'end', e.target.value)}
            className="w-32"
          />
        </div>
      ))}
    </div>
  );
};

export default OutreachHours;