import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Conversation.css';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import MainContainer from '../../components/MainContainer';
import { getPatientDetails } from '../../services/patientsService';
import { getConversationMessages } from '../../services/conversationService';
import { runDemo } from '../../services/RunDemoService';
import MessageContainer from '../../components/MessageContainer';
import { useNavigate } from 'react-router-dom'; 



const ConversationPage = () => {
    const { id, practiceId: practiceIdFromUrl } = useParams();
    const [patientDetails, setPatientDetails] = useState(null);
    const [error, setError] = useState(null); 
    const [conversationMessages, setConversationMessages] = useState([]);
    const navigate = useNavigate();

    // only used for tester
    const [isSuccessful, setIsSuccessful] = useState(false);
    const [reason, setReason] = useState('');
    const handleToggle = () => setIsSuccessful(!isSuccessful);
    const handleReasonChange = (e) => setReason(e.target.value);

    // const formatDate = (dateString) => {
    //     console.log("formatDate: dateString: ", dateString);
    //     if (!dateString) {
    //         return "Not currently scheduled";
    //     }
    //     // Check if dateString is missing minutes part and add ":00" if necessary
    //     if (dateString.match(/\dT\d{2}$/)) {
    //         dateString += ":00";
    //     }
    //     const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    //     return new Date(dateString).toLocaleString('en-US', options);
    // };

    const testerEndClick = () => {
        if ((isSuccessful && reason === '') || (!isSuccessful && reason !== '')) {
            // Your runDemo or other actions go here, passing the needed values.
            runDemo("test1", patient.PhoneNumber, "tester", -1, isSuccessful, reason);
        } else {
            alert("Please either mark as successful or provide a reason for not being successful. If successful there should not be a reason.");
        }
    };

    // const getStatusClassName = (status) => {
    //     let baseClass = 'status-tag '; // Base class
    //     console.log("getStatusClassName status: ", status)
    //     if (status) {
    //         switch (status.toLowerCase()) {
    //             case 'confirmed':
    //                 return baseClass + 'status-confirmed';
    //             case 'tentative':
    //                 return baseClass + 'status-unconfirmed';
    //             case 'unscheduled':
    //                 return baseClass + 'status-unscheduled';
    //             default:
    //                 return baseClass + 'status-default'; // Default class if status doesn't match
    //         }
    //     } else {
    //         return baseClass + 'status-unscheduled'; // Return a default class when status is null/undefined
    //     }
    // }


    useEffect(() => {

        let intervalId;

        const fetchData = async () => {
            try {
                let practiceId = practiceIdFromUrl;

                if (!practiceId) {
                    practiceId = localStorage.getItem('practiceId');
                    if (!practiceId) {
                      console.log('No practiceId found in local storage');
                      navigate('/');
                      return;
                    }
                  }
                // Fetch Patient Details
                const details = await getPatientDetails(id, practiceId);
                console.log('Fetched Patient Details:', details);
                setPatientDetails(details);

                // Check if patient details are available and has a phone number
                console.log("details", details)
                console.log("details.PhoneNumber", details[0].PhoneNumber)

                if (details && details[0].PhoneNumber) {
                    
                    const phoneNumber = details[0].PhoneNumber;
                    console.log('phoneNumber:', phoneNumber);

                    // Fetch Conversation Messages
                    const messages = await getConversationMessages(practiceId, phoneNumber);
                    const filteredMessages = [];
                    console.log("messages222", messages)

                    for (const message of messages.message) {
                        if (message.sender !== "system2") {
                            filteredMessages.push(message);
                        }
                    }
                    
                    setConversationMessages(filteredMessages); // Set the messages to state
                    console.log('filteredmessages:', filteredMessages);
                } else {
                    console.log('Patient details or phone number not available');
                }
            } catch (error) {
                console.error('Error:', error);
                setError(error.message);
            }
        };

        if (id) {
            fetchData();

            intervalId = setInterval(fetchData, 1000);
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [id, navigate, practiceIdFromUrl]); // Depend on idf
    
    
    
    if (error) return <div>Error: {error}</div>;

    const patient = patientDetails && patientDetails.length > 0 ? patientDetails[0] : null;
    console.log("here it is"+ patientDetails)

    return (
        <>
        <Header/>
        <div className="flex-container">
            <Sidebar />
            <MainContainer title={patient ? patient.Name : ''}>
                <div className="patient-container">
                        {patient && patient.practiceId.startsWith("tester") && (
                            <div>
                                <button onClick={testerEndClick}>Restart Convo</button>
                                <label>
                                    Successful
                                    <input type="checkbox" checked={isSuccessful} onChange={handleToggle} />
                                </label>
                                <input type="text" placeholder="Reason not successful" value={reason} onChange={handleReasonChange} />
                            </div>
                        )}
                    
                    <div className='patient-info-container'>
                        <div>Phone: {patient ? patient.PhoneNumber : ''}</div>
                        <div> Status: n/a</div>
                        <div>Next Appointment Date: n/a</div>
                    </div>

                    <MessageContainer messages={conversationMessages} practiceId={patient ? patient.practiceId : 'defaultPracticeId'} phoneNumber={patient ? patient.PhoneNumber : '0'} />


        
                </div>
            </MainContainer>
        </div>
    </>
    );
};

export default ConversationPage;
