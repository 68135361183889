import React from 'react';
import './MainContainer.css';

const MainContainer = ({ title, children }) => {
  return (
    <div className="main-container">
      <header className="main-title"><h1>{title}</h1></header>
      <main className="main-content">{children}</main>
    </div>
  );
}

export default MainContainer;
