import React, { useState, useEffect } from 'react';
import './Header.css';
import logo from '../assets/logo-header.png';
import { useNavigate } from 'react-router-dom'; 
// import { getUserDetails } from '../services/userInfoService';

const Header = () => {
    const [practiceId, setPracticeId] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        var practiceId = ""
        if (localStorage.getItem('practiceId')) {
            practiceId = localStorage.getItem('practiceId');
            console.log(practiceId);
            if(practiceId === "den"){
                practiceId = "Dentologie"
            }
            setPracticeId(practiceId)
        } else {
            console.log('No practiceId found in local storage');
            navigate('/'); 
        }
    }, [setError, navigate]);

    return (
        <div className='header'>
            <img src={logo} alt="Company Logo" className="header-company-logo" />
            <div className='user-info-box'>
                {practiceId && (
                    <>
                        <div className='practiceId'> {practiceId} </div>
                    </>
                )}
            </div>
            {error && <div className="error-message">{error}</div>}
        </div>
    );
};

export default Header;
