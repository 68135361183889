import React, {useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "../ui/Dialog"
  import { Button } from '../ui/Button';
  import {Undo2, Undo2Icon, UndoIcon, UserRoundPlus} from 'lucide-react';
 

  import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
  } from "../ui/Table"
import { Textarea } from '../components/Textarea';
  






const Consent = ({title, subtitle, children}) => {

 



  return (
    <div >
                 <DialogContent className="h-96">


                      <DialogTitle>Consent for Treatment</DialogTitle>

                      <Textarea value="You can't sue if I ruin your teeth"/>
           
                 </DialogContent>
    </div>
  );
};

export default Consent;
