 const BASE_URL = process.env.REACT_APP_API_URL ||  'https://dentalexpressserver.azurewebsites.net';
//const BASE_URL = 'http://localhost:3001'

function fetchFromAPI(endpoint, options = {}) {
    return fetch(`${BASE_URL}${endpoint}`, options)
        .then(response => {
            return response.json().then(data => {
                if (!response.ok) {
                    // Handle non-2xx HTTP responses
                    const error = new Error(data.error || 'Unknown error');
                    error.status = response.status;
                    throw error;
                }
                return { data, status: response.status }; // Include the status code
            });
        });
}

async function runDemo(name, phoneNumber, practiceId, demoType, bSuccessful=false, reason="", apptDate="") {
    try {
        console.log("demoType ", demoType);
        const endpoint = '/api/startDemo';
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, phoneNumber, practiceId, demoType, bSuccessful, reason, apptDate }) // Pass parameters in the request body
        };

        console.log("runDemo options ", options);

        // if(demoType === 3)
        // {
        //     return runVoiceDemo(name, phoneNumber, practiceId, demoType, bSuccessful=false, reason="");
        // }

        const { data, status } = await fetchFromAPI(endpoint, options);
        console.log('Azure Function response:', data, 'Status Code:', status);
        return { data, status }; // Return both data and status
    } catch (error) {
        console.error('Error calling Azure Function:', error.message);
        throw error; 
    }
}

async function getLockStatus(practiceId) {
    try {
        const endpoint = `/api/practices/${practiceId}/lockStatus`;
        const options = {
            method: 'GET', // GET request to the endpoint
            headers: {
                'Content-Type': 'application/json'
            }
        };

        console.log("harry kane ", `${BASE_URL}${endpoint}`)

        const { data, status } = await fetchFromAPI(endpoint, options);
        console.log('API response:', data, 'Status Code:', status);
        return data.lockStatus; // Return only the lockStatus
    } catch (error) {
        console.error('Error calling API:', error.message);
        return false; // Return false in case of an error
    }
}

// async function runVoiceDemo(name, phoneNumber, practiceId, demoType, bSuccessful=false, reason="")
// {
//     try
//     {
//         const currentDate = new Date();
//         const oneMonthLater = new Date();
//         oneMonthLater.setMonth(currentDate.getMonth() + 1);
//         const date1 = currentDate.toISOString().slice(0, 19);
//         const date2 = oneMonthLater.toISOString().slice(0, 19);
//         console.log("date1 ", date1);
//         console.log("date2 ", date2);

//         const endpoint = '/openings';
//         const queryParams = new URLSearchParams({
//         date1,
//         date2,
//         practiceId,
//         name,
//         phoneNumber
//         });

//         const url = `${endpoint}?${queryParams.toString()}`;

//         const options = {
//         method: 'GET',
//         headers: {
//             'Content-Type': 'application/json'
//         }
//         };

//         await fetchFromAPI(url, options);
//     }
//     catch (error) {
//         console.error('Error in runVoiceDemo:', error);
//         // Handle the error or throw it if needed
//         throw error;
//       }
// }


export { runDemo, getLockStatus };
