import React, { useState, useEffect } from 'react';
 import './MessagesPage.css'; // Your CSS for styling
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import MainContainer from '../../components/MainContainer';
import { getConversationsByPractice } from '../../services/messagesService'; // Assume this is your API call
import { useNavigate } from 'react-router-dom';

const MessagesPage = () => {
    const [conversations, setConversations] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1); 
    const navigate = useNavigate();
    
    console.log("hello")

    useEffect(() => {
        const practiceId = localStorage.getItem('practiceId') || '';
        if (!practiceId) {
            console.log('No practiceId found in local storage');
            navigate('/');
            return;
        }

        var practiceIdForActivities = practiceId;
        if(practiceId === "Admin")
        {
            practiceIdForActivities = ['Jemin', 'Chua', 'wynn', "starBrite", "Jemin2", "GraceDental", "IAS", "nkc1", "starBriteFremont"];
        }        

        getConversationsByPractice(practiceIdForActivities, currentPage, 10, searchTerm)
            .then(data => {
                const modifiedConversations = data.map(convo => ({
                    id: convo.id,
                    name: convo.name,
                    lastMessage: convo.message[convo.message.length - 1].message,
                    url: `/conversation/${convo.PatientId}/${convo.practiceId}`
                }));

                setConversations(modifiedConversations);
            })
            .catch(error => setError(error));
    }, [navigate, searchTerm, currentPage]);

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    return (
        <>
            <Header/>
            <div className="flex-container">
                <Sidebar />
                <MainContainer title="Messages">
                    <input
                        type="text"
                        placeholder="Search Conversations..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ margin: '20px 0', padding: '10px', width: 'calc(100% - 40px)' }} // Adjust the width as needed
                    />
                    <div className="messages-container">
                        {conversations.map((convo, index) => (
                            <div key={index} className="conversation-summary" onClick={() => navigate(convo.url)}>
                                <div className="conversation-name">{convo.name}</div>
                                <div className="last-message">{convo.lastMessage}</div>
                            </div>
                        ))}
                    </div>
                    <div className="pagination-buttons">
                        <button
                            onClick={() => setCurrentPage(currentPage => Math.max(1, currentPage - 1))}
                            disabled={currentPage === 1}
                        >
                            Previous Page
                        </button>
                        <button
                            onClick={() => setCurrentPage(currentPage => currentPage + 1)}
                        >
                            Next Page
                        </button>
                    </div>
                </MainContainer>
            </div>
        </>
    );
};

export default MessagesPage;