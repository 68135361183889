const BASE_URL = process.env.REACT_APP_API_URL || 'https://dentalexpressserver.azurewebsites.net';
//const BASE_URL = 'http://localhost:3001'


const formatDate = (dateString) => {
    // Check if the dateString ends with an incomplete time (e.g., 'T12')
    if (/T\d{1,2}$/.test(dateString)) {
        dateString += ':00'; // Append ':00' to make it a complete hour-minute format
    }

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
        // Date is invalid
        return 'Invalid Date Format';
    }

    return date.toLocaleDateString(undefined, options).replace(/, (\d{4})/, ', $1');
};

// Function to rename status
const renameStatus = (status) => {
    if (status === null) {
        return 'Unscheduled'; 
    }

    return status.toLowerCase() === 'tentative' ? 'Unconfirmed' : status;
};

// Function to get patients of a specific practice with pagination
const getPatientsByPractice = async (practiceId, name, page = 1, pageSize = 10, searchTerm = '', bSch=true) => {
    try {

        console.log("searchTerm2 ", searchTerm)

        // Constructing the URL with the practiceId and pagination parameters
        const url = `${BASE_URL}/api/patients/${practiceId}?name=${encodeURIComponent(name)}&page=${page}&pageSize=${pageSize}&searchTerm=${searchTerm}&bSch=${bSch}`;
        
        console.log("hello this is the patients url2: ", url)

        // Making the GET request using fetch
        const response = await fetch(url);

        // Checking if the response is successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parsing the response body as JSON
        const data = await response.json();

        console.log("data", data)

        // Reformatting the NextAppointment date for each patient
        const formattedData = data.patients.map(patient => {
            return {
                ...patient,
                NextAppointment: patient.NextAppointment ? formatDate(patient.NextAppointment) : 'Not Scheduled',
                NextAppointmentStatus: renameStatus(patient.NextAppointmentStatus)
            };
        });

        console.log("formattedData ", formattedData)

        return { ...data, patients: formattedData };
    } catch (error) {
        // Handle any errors here (e.g., logging, notifying the user)
        console.error('Error fetching patients:', error);
        throw error;
    }
};

const getConfirmationsByPractice = async (practiceId, page = 1, pageSize = 10, searchTerm = '') => {
    try {
        console.log("searchTerm: ", searchTerm);

        // Constructing the URL with the practiceId and pagination parameters
        const url = `${BASE_URL}/api/confirmations/${practiceId}?page=${page}&pageSize=${pageSize}&searchTerm=${encodeURIComponent(searchTerm)}`;

        console.log("Confirmations URL: ", url);

        // Making the GET request using fetch
        const response = await fetch(url);

        // Checking if the response is successful
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        // Parsing the response body as JSON
        const data = await response.json();

        console.log("Data: ", data);

        // Assuming there's a need to format some fields in the confirmation documents
        const formattedData = data.confirmations.map(confirmation => {
            // Example formatting (adjust according to actual data structure and needs)
            return {
                ...confirmation
            };
        });

        console.log("Formatted Data: ", formattedData);

        return { ...data, confirmations: formattedData };
    } catch (error) {
        console.error('Error fetching confirmations:', error);
        throw error;
    }
};



const getPatientDetails = async (patientId, practiceId) => {
    try {
        const response = await fetch(`${BASE_URL}/api/patient/${patientId}/${practiceId}`);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const patientDetails = await response.json();
        console.log("Patient details getPatientDetails: ", patientDetails);
        return patientDetails;
    } catch (error) {
        console.error('Error fetching patient details:', error);
        throw error;
    }
};



const sendMessage = async (message, practiceId, phoneNumber, bInternal = false) => {
    try {
      // Specify the server endpoint URL
      var url = `${BASE_URL}/api/sendMessage`;

      console.log("sendMessage practiceId:", practiceId);

      var body = JSON.stringify({ phoneNumber, message, practiceId, bInternal});

      console.log("body: sendMessage ", phoneNumber, message, practiceId, bInternal);

      if(practiceId.startsWith("tester"))
      {
        url = `${BASE_URL}/api/tester`;
      }

      console.log("sendMessage url ", url)
  
      // Make the POST request using fetch
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: body,
      });
  
      // Check for successful response
      if (!response.ok) {
        // Handle errors
        // throw new Error(`Error: ${response.status}`);
        return null;
      }
  
      // Parse and return the response body for further processing
      const data = await response.json();
      return data;
    } catch (error) {
      // Log or handle errors
      console.error("Failed to send message:", error);
      throw error;
    }
  };
  


  

export { getPatientsByPractice, getPatientDetails, sendMessage, getConfirmationsByPractice };
