import React, { useState } from 'react';
import OnboardingLayout from '../../components/layout/OnboardingLayout';
import ProgressTracker from '../../components/ProgressTracker';
import FormInput from '../../components/FormInput';
import FormButton from '../../components/FormButton';

const OrganizationRegistrationPage = () => {

    const [inputValue, setInputValue] = useState(''); // State to hold the input value

    const handleChange = (event) => {
      setInputValue(event.target.value); // Update state on input change
    };

    return(
        <OnboardingLayout>
            <ProgressTracker blackLinesCount={1} />
            <h2>Register your organization</h2>
            <FormInput 
                label="Organization name" 
                type="text" 
                value={inputValue} 
                onChange={handleChange}
                style={{ width: '400px' }}  
            />

            <FormButton
            label={'Continue'}
            />

        </OnboardingLayout>

    );

};


export default OrganizationRegistrationPage;

