import React, { useState, useEffect } from 'react';
import { Input } from '../ui/Input';
import { Checkbox } from '../ui/Checkbox';
import { RadioGroup, RadioGroupItem } from '../ui/RadioGroup';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/Select';
import { Textarea } from '../ui/TextArea';
import { Button } from '../ui/Button';
import { Label } from '../ui/Label';
import { Card, CardContent, CardHeader, CardTitle } from '../ui/Card';
import { fetchFormData, submitFormResponse } from '../services/userInfoService';


const FormInput = () => {
    const [formData, setFormData] = useState(null);
    const [formState, setFormState] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    useEffect(() => {
      const getFormData = async () => {
        try {
          const practiceId = 'Jemin'; // You might want to make this dynamic
          const type = 'GeneralInfo'; // You might want to make this dynamic
          const data = await fetchFormData(practiceId, type);
          console.log("form data ", data)
          setFormData(data["formSections"]);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
  
      getFormData();
    }, []);
  
    const handleInputChange = (fieldName, value) => {
      setFormState(prevState => ({
        ...prevState,
        [fieldName]: value
      }));
    };
  
    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
          const practiceId = 'Jemin'; // Make sure this matches your actual practiceId
          const type = 'GeneralInfo'; // Make sure this matches your actual form type
          const userId = 'USER_ID'; // You might want to get this from your authentication system
      
          const responseDocument = {
            type: "UserResponse",
            practiceId: practiceId,
            formId: `${practiceId}_${type}`,
            formType: type,
            userId: userId,
            submissionDate: new Date().toISOString(),
            lastUpdated: new Date().toISOString(),
            responses: formData.formSections.map(section => ({
              sectionName: section.sectionName,
              fields: section.fields.map(field => ({
                fieldName: field.fieldName,
                response: formState[field.fieldName] || ''
              }))
            }))
          };
      
          const result = await submitFormResponse(responseDocument);
          console.log('Form submitted successfully:', result);
          // You might want to show a success message to the user or redirect them
        } catch (error) {
          console.error('Error submitting form:', error);
          setError('Failed to submit form. Please try again.');
        }
      };
    
  
    const renderField = (field) => {
      switch (field.fieldType) {
        case 'text':
        case 'email':
        case 'tel':
          return (
            <Input
              type={field.fieldType}
              id={field.fieldName}
              value={formState[field.fieldName] || ''}
              onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
              required={field.required}
              maxLength={field.maxLength}
              pattern={field.pattern}
            />
          );
        case 'date':
          return (
            <Input
              type="date"
              id={field.fieldName}
              value={formState[field.fieldName] || ''}
              onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
              required={field.required}
            />
          );
        case 'radio':
          return (
            <RadioGroup
              onValueChange={(value) => handleInputChange(field.fieldName, value)}
              required={field.required}
            >
              {field.options.map((option) => (
                <div key={option} className="flex items-center space-x-2">
                  <RadioGroupItem value={option} id={`${field.fieldName}-${option}`} />
                  <Label htmlFor={`${field.fieldName}-${option}`}>{option}</Label>
                </div>
              ))}
            </RadioGroup>
          );
        case 'select':
          return (
            <Select
              onValueChange={(value) => handleInputChange(field.fieldName, value)}
              required={field.required}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select an option" />
              </SelectTrigger>
              <SelectContent>
                {field.options.map((option) => (
                  <SelectItem key={option} value={option}>{option}</SelectItem>
                ))}
              </SelectContent>
            </Select>
          );
        case 'checkboxGroup':
          return (
            <div className="grid grid-cols-2 gap-4">
              {field.options.map((option) => (
                <div key={option} className="flex items-center space-x-2">
                  <Checkbox
                    id={`${field.fieldName}-${option}`}
                    checked={formState[field.fieldName]?.includes(option) || false}
                    onCheckedChange={(checked) => {
                      const currentValues = formState[field.fieldName] || [];
                      const newValues = checked
                        ? [...currentValues, option]
                        : currentValues.filter(value => value !== option);
                      handleInputChange(field.fieldName, newValues);
                    }}
                  />
                  <Label htmlFor={`${field.fieldName}-${option}`}>{option}</Label>
                </div>
              ))}
            </div>
          );
        case 'textarea':
          return (
            <Textarea
              id={field.fieldName}
              value={formState[field.fieldName] || ''}
              onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
              required={field.required}
            />
          );
        default:
          return null;
      }
    };
  
    if (loading) {
      return <div>Loading...</div>;
    }
  
    if (error) {
      return <div>Error: {error}</div>;
    }

    
  
    return (
      <div className="container mx-auto p-4 max-w-4xl">
        <Card className="bg-white shadow-lg rounded-lg overflow-hidden">
          <CardHeader className="bg-gray-50 border-b border-gray-200 p-6">
            <CardTitle className="text-2xl font-bold text-gray-800">Patient Information Form</CardTitle>
          </CardHeader>
          <CardContent className="p-6">
            <form onSubmit={handleSubmit} className="space-y-8">
              {formData && formData.map((section) => (
                <div key={section.sectionName} className="space-y-4">
                  <h2 className="text-xl font-semibold text-gray-700 border-b border-gray-200 pb-2">{section.sectionName}</h2>
                  {section.fields.map((field) => (
                    <div key={field.fieldName} className="space-y-2">
                      <Label htmlFor={field.fieldName} className="text-sm font-medium text-gray-700">{field.label}</Label>
                      {renderField(field)}
                    </div>
                  ))}
                </div>
              ))}
              <div className="pt-4">
                <Button type="submit" className="w-full">Submit</Button>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    );
  };
  
  export default FormInput;