import React from 'react';
import './FormButton.css'; // Assuming you have a separate CSS file for styling

const FormButton = ({ label, onClick, disabled }) => {
  return (
    <div className="form-button">
      <button type="submit" onClick={onClick} disabled={disabled}>
        {label}
      </button>
    </div>
  );
};

export default FormButton;
