// UpdatePasswordPage.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContainer from '../../components/AuthContainer';
import FormInput from '../../components/FormInput';
import FormButton from '../../components/FormButton';
import logo from '../../assets/logo.png';
import { supabase } from '../../services/supabaseClient';

const UpdatePasswordPage = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
  
    useEffect(() => {
      supabase.auth.onAuthStateChange(async (event, session) => {
        if (event === "PASSWORD_RECOVERY") {
          setMessage("Please enter your new password.");
        }
      });
    }, []);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      setMessage('');
      setErrorMessage('');
  
      if (password !== confirmPassword) {
        setErrorMessage('Passwords do not match');
        return;
      }
  
      const { data, error } = await supabase.auth.updateUser({ password });
  
      if (data) {
        setMessage("Password updated successfully!");
      }
  
      if (error) {
        setErrorMessage("There was an error updating your password.");
      }
    };
  
    const handleLoginClick = () => {
        navigate('/login');
      };
    
      return (
        <div>
          <img src={logo} alt="Company Logo" className="company-logo" />
          <AuthContainer title="Update Password">
            {message && <p className="message">{message}</p>}
            {errorMessage && <p className="error">{errorMessage}</p>}
            {message === "Password updated successfully!" ? (
              <div>
                <p>Your password has been successfully updated.</p>
                <button onClick={handleLoginClick}>Go to Login</button>
              </div>
            ) : (
              <form onSubmit={handleSubmit}>
                <FormInput
                  label="New Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <FormInput
                  label="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <FormButton label="Update Password" type="submit" />
              </form>
            )}
          </AuthContainer>
        </div>
      );
    };
    
    export default UpdatePasswordPage;