import React, { useState } from 'react';
import { Button } from "../ui/Button";
import { Input } from "../ui/Input";
import { Label } from "../ui/Label";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "../ui/Card";
import { Alert, AlertDescription } from "../ui/Alert";
import { supabase } from '../services/supabaseClient';
import { useNavigate } from 'react-router-dom'; 

const OTPLoginPage = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otpCode, setOtpCode] = useState('');
    const [step, setStep] = useState('phone');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const navigate = useNavigate();
  
    const formatPhoneNumber = (number) => {
        // Remove all non-digit characters
        const digits = number.replace(/\D/g, '');
        
        // Ensure the number starts with the US country code
        if (digits.length === 10) {
          return `+1${digits}`;
        } else if (digits.length === 11 && digits.startsWith('1')) {
          return `+${digits}`;
        }
        return number; // Return original input if it doesn't match expected formats
      };
    
      const validatePhoneNumber = (number) => {
        // Basic validation for US numbers
        const phoneRegex = /^\+1[2-9]\d{9}$/;
        return phoneRegex.test(number);
      };

      const handleSendOTP = async () => {
        setError('');
        setSuccess('');
    
        const formattedNumber = formatPhoneNumber(phoneNumber);
        
        if (!validatePhoneNumber(formattedNumber)) {
          setError('Please enter a valid USA phone number.');
          return;
        }
    
        try {
          const { error } = await supabase.auth.signInWithOtp({
            phone: formattedNumber,
          });
    
          if (error) throw error;
    
          setStep('otp');
          setSuccess('OTP sent successfully. Please check your phone.');
        } catch (error) {
          setError(error.message);
        }
      };
    
  
    const handleVerifyOTP = async () => {
      try {
        let phoneNumber2 = "1" + phoneNumber;
        console.log("what is the phone number we are trying here: ", phoneNumber2)
        const { data, error } = await supabase.auth.verifyOtp({
          phone: phoneNumber2,
          token: otpCode,
          type: 'sms'
        });
  
        if (error) throw error;

        if (data.session && data.session.access_token) {
          // Store the JWT token
          localStorage.setItem('jwtToken', data.session.access_token);
          
          // Navigate to the forms page
          navigate('/forms');
        } else {
            throw new Error('Session is undefined, unable to retrieve JWT token');
        }
      } catch (error) {
        console.log("this is the error that we found in full", error);
        setError(error.message);
      }
    };
  
    return (
      <div className="min-h-screen bg-gray-100 flex items-center justify-center">
        <Card className="w-[350px]">
          <CardHeader>
            <CardTitle>Login</CardTitle>
            <CardDescription>
              {step === 'phone' 
                ? 'Enter your phone number to receive a login code.' 
                : 'Enter the OTP sent to your phone.'}
            </CardDescription>
          </CardHeader>
          <CardContent>
            {step === 'phone' ? (
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="phoneNumber">Phone Number</Label>
                  <Input
                    id="phoneNumber"
                    placeholder="Enter your phone number"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
              </div>
            ) : (
              <div className="grid w-full items-center gap-4">
                <div className="flex flex-col space-y-1.5">
                  <Label htmlFor="otpCode">OTP Code</Label>
                  <Input
                    id="otpCode"
                    placeholder="Enter the OTP"
                    value={otpCode}
                    onChange={(e) => setOtpCode(e.target.value)}
                  />
                </div>
              </div>
            )}
            {error && (
              <Alert variant="destructive" className="mt-4">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            {success && (
              <Alert variant="default" className="mt-4">
                <AlertDescription>{success}</AlertDescription>
              </Alert>
            )}
          </CardContent>
          <CardFooter className="flex justify-between">
            {step === 'phone' ? (
              <Button onClick={handleSendOTP} className="w-full">Send OTP</Button>
            ) : (
              <>
                <Button variant="outline" onClick={() => setStep('phone')}>Back</Button>
                <Button onClick={handleVerifyOTP}>Verify OTP</Button>
              </>
            )}
          </CardFooter>
        </Card>
      </div>
    );
  };
  
  export default OTPLoginPage;