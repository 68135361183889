import React, { useState, useEffect, useRef } from 'react';
import { Button } from "../ui/Button"
import { Input } from "../ui/Input"
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "../ui/Table"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/Tabs"
import { Phone, X, Mic, MicOff, Volume2, VolumeX } from 'lucide-react';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import { Device } from '@twilio/voice-sdk';
import axios from 'axios';
import { Alert, AlertDescription, AlertTitle } from '../ui/Alert';

const API_URL = 'https://68dd-184-23-234-162.ngrok-free.app/create-phone-call';

const DialerPage = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [callHistory, setCallHistory] = useState([]);
  const [activeTab, setActiveTab] = useState('dialer');
  const [practiceIdGlobal, setPracticeIdGlobal] = useState('');
  const [isCallActive, setIsCallActive] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isOnHold, setIsOnHold] = useState(false);
  const [callDuration, setCallDuration] = useState(0);
  const deviceRef = useRef(null);
  const connectionRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    const practiceId = localStorage.getItem('practiceId');
    if (practiceId) {
      setPracticeIdGlobal(practiceId);
    } else {
      console.log('No practiceId found in local storage');
    }

    const initializeTwilioDevice = async () => {
      console.log('Initializing Twilio Device...');
      try {
        console.log('Attempting to fetch Twilio token...');
        const response = await axios.get('https://voiceserver.azurewebsites.net/get-twilio-token');
        console.log('Axios response:', response);
    
        if (response.status !== 200) {
          throw new Error(`Failed to fetch Twilio token. Status: ${response.status}`);
        }
    
        const data = response.data;
        console.log("initializeTwilioDevice data:", data);
    
        if (!data.token) {
          throw new Error('Token not found in response');
        }
    
        console.log('Creating Twilio Device...');
        deviceRef.current = new Device(data.token, {
          edge: 'ashburn'
        });
        
        console.log('Setting up Twilio Device event listeners...');
        deviceRef.current.on('registered', () => console.log('Twilio Device is registered'));
        deviceRef.current.on('error', (error) => console.error('Twilio Device error:', error));
        deviceRef.current.on('connect', (conn) => {
          console.log('Twilio Device connected');
          connectionRef.current = conn;
          setIsCallActive(true);
        });
        deviceRef.current.on('disconnect', () => {
          console.log('Twilio Device disconnected');
          setIsCallActive(false);
          connectionRef.current = null;
        });
    
        console.log('Registering Twilio Device...');
        await deviceRef.current.register();
        console.log('Twilio Device registered successfully');
      } catch (error) {
        console.error('Failed to initialize Twilio Device:', error);
        if (error.response) {
          console.error('Error response data:', error.response.data);
          console.error('Error response status:', error.response.status);
          console.error('Error response headers:', error.response.headers);
        } else if (error.request) {
          console.error('Error request:', error.request);
        } else {
          console.error('Error message:', error.message);
        }
      }
    };

    initializeTwilioDevice();

    return () => {
      if (deviceRef.current) {
        deviceRef.current.destroy();
      }
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isCallActive) {
      timerRef.current = setInterval(() => {
        setCallDuration(prev => prev + 1);
      }, 1000);
    } else {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      setCallDuration(0);
    }
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [isCallActive]);

  const handleNumberClick = (num) => {
    setPhoneNumber(prevNumber => prevNumber + num);
  };

  const handleBackspace = () => {
    setPhoneNumber(prevNumber => prevNumber.slice(0, -1));
  };

  const formatPhoneNumber = (number) => {
    const cleanNumber = number.replace(/\D/g, '');
    if (cleanNumber.startsWith('1')) {
      return '+' + cleanNumber;
    } else {
      return '+1' + cleanNumber;
    }
  };

  const handleCall = async () => {
    if (phoneNumber && practiceIdGlobal) {
      const formattedNumber = formatPhoneNumber(phoneNumber);
      try {
        const response = await fetch(API_URL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            practiceId: practiceIdGlobal,
            to: formattedNumber,
            from: '+14159807772',
          }),
        });

        if (response.ok) {
          const result = await response.json();
          console.log('Call initiated:', result);
          setCallHistory(prev => [{number: formattedNumber, time: new Date().toLocaleString()}, ...prev]);
          setIsCallActive(true);
          deviceRef.current.connect({ To: 'ConferenceRoom' });
        } else {
          const errorText = await response.text();
          console.error('Failed to initiate call:', errorText);
          alert('Failed to initiate call. Please try again.');
        }
      } catch (error) {
        console.error("Error making call:", error);
        alert('An error occurred while trying to make the call. Please try again.');
      }
    } else {
      alert('Please enter a phone number and ensure you are logged in with a valid practice ID.');
    }
  };

  const handleHangup = () => {
    if (connectionRef.current) {
      connectionRef.current.disconnect();
    }
    setIsCallActive(false);
    setIsMuted(false);
    setIsOnHold(false);
  };

  const handleToggleMute = () => {
    if (connectionRef.current) {
      connectionRef.current.mute(!isMuted);
      setIsMuted(!isMuted);
    }
  };

  const handleToggleHold = () => {
    if (connectionRef.current) {
      if (isOnHold) {
        connectionRef.current.unhold();
      } else {
        connectionRef.current.hold();
      }
      setIsOnHold(!isOnHold);
    }
  };

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <Sidebar practiceId={practiceIdGlobal}/>
      </div>

      <div className="flex flex-col min-h-screen">
        <MainContent title="Dialer">
          {isCallActive && (
            <Alert className="mb-4">
              <AlertTitle>Active Call</AlertTitle>
              <AlertDescription>
                Call in progress with {phoneNumber} - Duration: {formatDuration(callDuration)}
              </AlertDescription>
            </Alert>
          )}
          
          <Tabs value={activeTab} onValueChange={setActiveTab}>
            <TabsList>
              <TabsTrigger value="dialer">Dialer</TabsTrigger>
              <TabsTrigger value="history">Call History</TabsTrigger>
            </TabsList>
            
            <TabsContent value="dialer">
              <div className="mb-4">
                <Input 
                  value={phoneNumber} 
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  className="text-2xl text-center"
                  disabled={isCallActive}
                />
              </div>
              
              <div className="grid grid-cols-3 gap-2 mb-4">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, '*', 0, '#'].map((num) => (
                  <Button key={num} onClick={() => handleNumberClick(num)} className="text-2xl py-6" disabled={isCallActive}>
                    {num}
                  </Button>
                ))}
              </div>
              
              <div className="flex justify-between">
                <Button onClick={handleBackspace} variant="outline" className="w-1/3" disabled={isCallActive}>
                  <X className="mr-2 h-4 w-4" /> Delete
                </Button>
                {isCallActive ? (
                  <Button onClick={handleHangup} variant="destructive" className="w-1/3">
                    <Phone className="mr-2 h-4 w-4" /> Hang Up
                  </Button>
                ) : (
                  <Button onClick={handleCall} variant="outline" className="w-1/3">
                    <Phone className="mr-2 h-4 w-4" /> Call
                  </Button>
                )}
              </div>

              {isCallActive && (
                <div className="mt-4 flex justify-center space-x-4">
                  <Button onClick={handleToggleMute} variant="outline" className={isMuted ? "bg-red-200" : ""}>
                    {isMuted ? <MicOff className="h-4 w-4" /> : <Mic className="h-4 w-4" />}
                    {isMuted ? " Unmute" : " Mute"}
                  </Button>
                  <Button onClick={handleToggleHold} variant="outline" className={isOnHold ? "bg-yellow-200" : ""}>
                    {isOnHold ? <Volume2 className="h-4 w-4" /> : <VolumeX className="h-4 w-4" />}
                    {isOnHold ? " Resume" : " Hold"}
                  </Button>
                </div>
              )}
            </TabsContent>
            
            <TabsContent value="history">
              <Table>
                <TableCaption>Recent Call History</TableCaption>
                <TableHeader>
                  <TableRow>
                    <TableHead>Number</TableHead>
                    <TableHead>Time</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {callHistory.map((call, index) => (
                    <TableRow key={index}>
                      <TableCell>{call.number}</TableCell>
                      <TableCell>{call.time}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TabsContent>
          </Tabs>
        </MainContent>
      </div>
    </div>
  );
};

export default DialerPage;