// DashboardPage.js
import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import AnalyticsContainer from '../../components/AnalyticsContainer';
import './dashboardPage.css';
import { getPracticeStats } from '../../services/ActivityService';

const DashboardPage = () => {
  const [selectedPracticeId, setSelectedPracticeId] = useState(null);
  const [practiceStats, setPracticeStats] = useState(null);

  const practiceIds = ["Jemin", "Chua", "wynn", "Jemin2", "starBrite", "GraceDental", "IAS", "nkc1"]; // Replace with actual practiceIds

  useEffect(() => {
    const fetchPracticeStats = async () => {
      if (selectedPracticeId) {
        try {
          const stats = await getPracticeStats(selectedPracticeId);
          console.log(stats)
          setPracticeStats(stats);
        } catch (error) {
          console.error('Error fetching practice stats:', error);
        }
      }
    };

    fetchPracticeStats();
  }, [selectedPracticeId]);

  const handlePracticeClick = (practiceId) => {
    setSelectedPracticeId(practiceId);
  };

  return (
    <>
      <Header />
      <div className="flex-container">
        <Sidebar />
        <div className="dashboard-content">
          <div className="top-buttons">
            {practiceIds.map((practiceId) => (
              <button
                key={practiceId}
                onClick={() => handlePracticeClick(practiceId)}
              >
                {practiceId}
              </button>
            ))}
          </div>
          {practiceStats && (
            <>
            <div className="row">
              <AnalyticsContainer
                title="Total Messages"
                number={practiceStats.totalMessages.toString()}
              />
              <AnalyticsContainer
                title="Total Bookings"
                number={practiceStats.totalActivities.toString()}
              />
            </div>
            <div className="row">
              <AnalyticsContainer
                title="Total Responses"
                number={practiceStats.totalResponses.toString()}
              />
              <AnalyticsContainer
                title="Patients Left To Message"
                number={practiceStats.PatientsLeftToMessage?.toString() ?? 'Data not available'}
              />
            </div>
          </>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardPage;