import React, { useState, useEffect } from 'react';
import { Input } from '../ui/Input';
import { Search, SettingsIcon, HelpCircle, Bell } from 'lucide-react';
import {
  Dialog,
  DialogTrigger,
} from "./Dialog"
import Settings from '../views/Settings';
import KnowledgeBase from '../views/KnowledgeBase';
import HelpCenter from '../views/HelpCenter';
import { getPracticeStats } from '../services/ActivityService';
import AdminPracticeSelector from './MainContentAdmin';

const MainContent = ({ title, subtitle, children }) => {
  const [practiceInfo, setPracticeInfo] = useState(null);
  const [practiceId, setPracticeId] = useState(localStorage.getItem('practiceId') || '');
  const [practiceIdAdmin, setPracticeIdAdmin] = useState(localStorage.getItem('practiceIdAdmin') || '');

  const fetchPracticeInfo = async (id) => {
    if (!id) {
      console.log('No practiceId found');
      return;
    }

    try {
      const practiceInfoData = await getPracticeStats(id);
      console.log("practiceInfoData ", practiceInfoData);
      setPracticeInfo(practiceInfoData);
    } catch (error) {
      console.error('Error fetching practice info:', error);
    }
  };

  useEffect(() => {
    fetchPracticeInfo(practiceId);
  }, [practiceId]);

  const handlePracticeChange = (newPracticeId) => {
    setPracticeId(newPracticeId);
    localStorage.setItem('practiceId', newPracticeId);
    fetchPracticeInfo(newPracticeId);
    window.location.reload();
  };

  console.log("practiceIdAdmin ", practiceIdAdmin)
  const isAdminView = practiceIdAdmin.includes('Admin');

  return (
    <div className="flex flex-col h-full w-full">
      <header className="flex h-14 items-center border-b gap-4 bg-muted/40 px-4 lg:h-[60px] lg:px-6">
        <div className="w-full flex-1">
          <form>
            <div className="relative">
              <Search className="absolute left-2.5 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                type="search"
                placeholder="Search..."
                className="w-full appearance-none bg-background pl-8 shadow-none md:w-2/3 lg:w-1/3"
              />
              <div className="flex items-center absolute right-2.5 top-1 h-full py-0 space-x-8">
                <Dialog>
                  <DialogTrigger className="text-sm text-muted-foreground leading-tight hover:text-foreground transition-colors">
                    Knowledge Base
                  </DialogTrigger>
                  <KnowledgeBase/>
                </Dialog>
                <Dialog>
                  <DialogTrigger className="text-sm">
                    <HelpCircle className="h-5 w-5 text-muted-foreground hover:text-foreground transition-colors" />
                  </DialogTrigger>
                  <HelpCenter/>
                </Dialog>
                <button className="text-sm">
                  <Bell className="h-5 w-5 text-muted-foreground hover:text-foreground transition-colors" />
                </button>
                <Dialog>
                  <DialogTrigger className="text-sm">
                    <SettingsIcon className='h-5 w-5 text-muted-foreground hover:text-foreground transition-colors'/>
                  </DialogTrigger>
                  <Settings/>
                </Dialog>
                {isAdminView ? (
                  <AdminPracticeSelector
                    currentPracticeId={practiceId}
                    onPracticeChange={handlePracticeChange}
                  />
                ) : (
                  <div className="flex flex-col justify-center h-full">
                    <span className="text-lg font-semibold leading-tight">
                      {practiceInfo ? practiceInfo.name : 'Loading...'}
                    </span>
                    <span className="text-sm text-muted-foreground leading-tight">
                      {practiceInfo ? practiceInfo.location : 'Loading...'}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </header>
      <main className="flex flex-col flex-1 pt-4 pr-4 pl-4 lg:pt-6 lg:pr-6 lg:pl-6">
        <h1 className="text-lg font-semibold md:text-2xl">{title}</h1>
        <h4 className="text-muted-foreground leading-tight">{subtitle}</h4>
        <div className="mt-4 flex-1 overflow-auto">
          {children}
        </div>
      </main>
    </div>
  );
};

export default MainContent;