import React, { useState, useEffect } from 'react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/Select"
import { getAnalyticsData } from '../services/ActivityService';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "../ui/Card"
const CircularProgressBar = ({ percentage, title }) => {
    const radius = 30;
    const circumference = 2 * Math.PI * radius;
    const strokeDasharray = `${circumference} ${circumference}`;
    const strokeDashoffset = circumference - (percentage / 100) * circumference;
    
    return (
      <Card className="flex flex-col items-center justify-center p-2">
        <div className="relative w-20 h-20">
          <svg className="w-full h-full" viewBox="0 0 100 100">
            <circle
              cx="50"
              cy="50"
              r={radius}
              fill="transparent"
              stroke="#e5e7eb"
              strokeWidth="10"
            />
            <circle
              cx="50"
              cy="50"
              r={radius}
              fill="transparent"
              stroke="#3b82f6"
              strokeWidth="10"
              strokeLinecap="round"
              style={{
                strokeDasharray,
                strokeDashoffset,
                transform: 'rotate(-90deg)',
                transformOrigin: '50% 50%'
              }}
            />
          </svg>
          <div className="absolute inset-0 flex flex-col items-center justify-center">
            <span className="text-lg font-bold">{percentage}%</span>
          </div>
        </div>
        <h3 className="mt-1 text-xs font-semibold text-center">{title}</h3>
      </Card>
    );
  };
  
  const StatCard = ({ title, value }) => (
    <Card className="flex flex-col items-center justify-center p-2">
      <h3 className="text-xs font-semibold text-center mb-1">{title}</h3>
      <div className="text-lg font-bold">{value}</div>
    </Card>
  );
  const AppointmentStatusCard = ({ data }) => (
    <Card className="col-span-2 sm:col-span-3 md:col-span-4">
      <CardHeader>
        <CardTitle className="text-sm font-semibold">Appointment Status of Hemingway Appointments</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-3 gap-2">
          {Object.entries(data).map(([status, count]) => (
            status !== 'Scheduled' && (
              <div key={status} className="text-center">
                <div className="text-sm font-medium">{status}</div>
                <div className="text-lg font-bold">{count}</div>
              </div>
            )
          ))}
        </div>
      </CardContent>
    </Card>
  );
  
  const AnalyticsDashboard = ({ practiceId }) => {
    const [timeRange, setTimeRange] = useState('6');
    const [data, setData] = useState({});
  
    useEffect(() => {
      const fetchedData = getAnalyticsData(parseInt(timeRange), practiceId);
      setData(fetchedData);
    }, [timeRange, practiceId]);
  
    const renderStat = (Component, props) => {
        const value = props.percentage !== undefined ? props.percentage : props.value;
        return value !== null && value !== undefined && value !== 0 ? <Component {...props} /> : null;
      };
  
    return (
      <div className="p-4 max-w-4xl mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold">Analytics Dashboard</h1>
          <Select value={timeRange} onValueChange={setTimeRange}>
            <SelectTrigger className="w-[140px]">
              <SelectValue placeholder="Select time range" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="1">Last 1 month</SelectItem>
              <SelectItem value="3">Last 3 months</SelectItem>
              <SelectItem value="6">Last 6 months</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
          {renderStat(CircularProgressBar, { percentage: data.totalHygieneOccupancy, title: "Total Hygiene Occupancy" })}
          {renderStat(CircularProgressBar, { percentage: data.sameDayBooking, title: "Same Day Booking" })}
          {renderStat(CircularProgressBar, { percentage: data.patientsBookedForNextCleaning, title: "Patients Booked for Next Cleaning" })}
          {renderStat(CircularProgressBar, { percentage: data.brokenAppointments, title: "Broken Appointments" })}
          {renderStat(CircularProgressBar, { percentage: data.reactivationConversationPercentage, title: "Reactivation Conversation" })}
          {renderStat(StatCard, { title: "Reactivation Messages", value: data.reactivationMessages })}
          {renderStat(StatCard, { title: "New Patients Booked by Hemingway", value: data.newPatientsBookedByHemingway })}
          {renderStat(StatCard, { title: "Calls Answered by Hemingway", value: data.callsAnsweredByHemingway })}
          {practiceId === "wynn" && data.appointmentStatus && (
            <AppointmentStatusCard data={data.appointmentStatus} />
          )}
        </div>
      </div>
    );
  };
  
  export default AnalyticsDashboard;