const BASE_URL = process.env.REACT_APP_API_URL || 'https://dentalexpressserver.azurewebsites.net';

function fetchFromAPI(endpoint) {
    return fetch(`${BASE_URL}${endpoint}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        });
}

async function getCalendarDates(practiceId, startDate, endDate) {
    try {
        
        var endpoint = '/api/calendar/' + practiceId + '?startTime=' + startDate + '&endTime=' + endDate;
        console.log("endpoint ", `${BASE_URL}${endpoint}`)

        const calendarDates = await fetchFromAPI(endpoint);
        console.log("array" + calendarDates);

        if (!calendarDates) {
            throw new Error('Calendar dates not found for user');
        }
        const filteredCalendarDates = calendarDates.filter(entry => entry.PatientName != null);

        return filteredCalendarDates;
    } catch (error) {
        console.error('Error fetching user details:', error);
        throw error;
    }
}

export { getCalendarDates };