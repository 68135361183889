// AnalyticsContainer.js
import React from 'react';
import './AnalyticsContainer.css'; // Import the CSS file

const AnalyticsContainer = ({ title, number }) => {
  // Split the number string by commas to create an array, and map through it to create a list
  const numberLines = number.split(',').map((line, index) => (
    <div key={index}>{line.trim()}</div>
  ));

  return (
    <div className="analytics-container">
      <div className="title">{title}</div>
      <div className="number">{numberLines}</div>
    </div>
  );
}

export default AnalyticsContainer;
