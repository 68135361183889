import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Table.css';

const Table = ({ data, columns }) => {
  const navigate = useNavigate();

  const getStatusClass = (status) => {
    console.log("wtf is going on!!!!!", status)
    if (!status) return 'status-tag';
    console.log("status", status)
    switch (status.toLowerCase()) {
      case 'confirmed':
      case 'active': // Added this case
      case 'scheduled': // Added this case
        return 'status-tag status-active';
      case 'unconfirmed':
        return 'status-tag status-unconfirmed';
      case 'unscheduled':
        return 'status-tag status-unscheduled';
      default:
        return 'status-tag';
    }
  };

  const formatDate = (dateStr) => {
    console.log(dateStr);
    if (!dateStr || dateStr === 'N/A') return 'N/A'; // Handle null dates
    if (dateStr.length === 13 && dateStr[10] === 'T') {
      dateStr += ':00'; // Append ':00' to represent minutes
    }
    const date = new Date(dateStr);
    return date.toLocaleDateString(); // Format date to a human-readable string (date only)
  };

  const handleRowClick = (url) => {
    navigate(url);
  };

  return (
    <table className='table'>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex} onClick={() => handleRowClick(row.url)} style={{ cursor: 'pointer' }}>
            {columns.map((column, colIndex) => (
              <td key={colIndex}>
                {(column.key === 'ConversationStatus' || column.key === 'ScheduleStatus') ? (
                  <span className={getStatusClass(row[column.key])}>
                    {row[column.key]}
                  </span>
                ) : column.key === 'LastCleaningDate' && !row[column.key] ? ( 
                  'N/A'  // Display 'N/A' if LastCleaningDate is null or undefined
                ) : column.key === 'LastCleaningDate'? ( 
                  formatDate(row[column.key])
                ) : (
                  row[column.key]
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
