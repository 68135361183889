import React, {useState} from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
  } from "../ui/Dialog"
import { Button } from '../ui/Button';
import {Undo2, Undo2Icon, UndoIcon, UserRoundPlus} from 'lucide-react';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../ui/Tabs"

import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table";

import Form from '../views/Form';
import Consent from '../views/Consent';
import ReactivationView from './ReactivationView'; // Import the ReactivationView component

const Settings = () => {
  const menuItems = [
    { name: 'Intake', icon: <UserRoundPlus className="w-4 h-4" />, content: 'Intake' },
    { name: 'Reactivation', icon: <Undo2Icon className="w-4 h-4" />, content: 'Reactivation' },
  ];

  const [activeItem, setActiveItem] = useState('Intake');

  return (
    <div>
      <DialogContent>
        <div className='flex items-center'>
        </div>
        <div className='flex h-96'>
          <div className='w-1/4 flex flex-col justify-between'>
            <DialogTitle>Settings</DialogTitle>
            <div className="grid items-start px-2 text-sm font-medium lg:px-4 mx-4">
              {menuItems.map((item) => (
                <div
                  key={item.name}
                  onClick={() => setActiveItem(item.name)}
                  className={`flex items-center gap-3 rounded-lg px-3 py-2 transition-all hover:text-primary ${
                    activeItem === item.name ? 'bg-muted text-primary' : 'text-muted-foreground'
                  }`}
                >
                  {item.icon}
                  {item.name}
                </div>
              ))}
            </div>
          </div>
          <div className='w-3/4'>
            {activeItem === 'Intake' && (
              <Tabs defaultValue="forms" className="w-[400px]">
                <TabsList>
                  <TabsTrigger value="forms">Patient Information</TabsTrigger>
                  <TabsTrigger value="consent">Consent and Authorization</TabsTrigger>
                </TabsList>
                <TabsContent value="forms">
                  <Table>
                    <TableCaption>A list of your forms.</TableCaption>
                    <TableBody>
                      <TableRow>
                        <TableCell className="font-medium">General Info</TableCell>
                        <TableCell><Button>Preview</Button></TableCell>
                        <TableCell>
                          <Dialog><DialogTrigger><Button>Edit</Button></DialogTrigger><Form/></Dialog>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TabsContent>
                <TabsContent value="consent">
                  <Table>
                    <TableCaption>A list of your forms.</TableCaption>                  
                    <TableBody>
                      <TableRow>
                        <TableCell className="font-medium">Consent for Treatment</TableCell>
                        <TableCell><Button>Preview</Button></TableCell>
                        <TableCell>                 
                          <Dialog><DialogTrigger><Button>Edit</Button></DialogTrigger><Consent/></Dialog>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TabsContent>
              </Tabs>
            )}
            {activeItem === 'Reactivation' && <ReactivationView />}
          </div>
        </div>
      </DialogContent>
    </div>
  );
};

export default Settings;