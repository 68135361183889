import React from 'react';
import { useParams } from 'react-router-dom';
import './PatientPage.css'; // Ensure this CSS file styles the components appropriately
import Header from '../../components/Header'; // Adjust the path as necessary
import Sidebar from '../../components/Sidebar'; // Adjust the path as necessary

const PatientPage = () => {
    const { id } = useParams(); // Extract the patient ID from URL parameters

    // Dummy patient data, including the ID extracted from the URL
    const patient = {
        id,
        name: 'John Doe',
        age: 30,
        phoneNumber: '123-456-7890',
        currentAppointmentStatus: 'Confirmed',
        lastAppointmentDate: '2023-01-15',
        nextAppointmentDate: '2023-02-20'
    };

    // Dummy appointment history data
    const appointmentHistory = [
        { date: '2022-12-01', type: 'cleaning', notes: 'n/a' },
        { date: '2022-10-07', type: 'root canel', notes: 'new job' },
        { date: '2022-08-23', type: 'cavity', notes: 'had lots of pain' }
    ];

    return (
        <>
            <Header />
            <div className="flex-container">
                <Sidebar />
                <div className="patient-page">
                    <div className="patient-info-box">
                        <h2>Patient Information</h2>
                        <p>ID: {patient.id}</p>
                        <p>Name: {patient.name}</p>
                        <p>Age: {patient.age}</p>
                        <p>Phone Number: {patient.phoneNumber}</p>
                        <p>Current Appointment Status: {patient.currentAppointmentStatus}</p>
                        <p>Last Appointment Date: {patient.lastAppointmentDate}</p>
                        <p>Next Appointment Date: {patient.nextAppointmentDate}</p>
                    </div>
                    <div className="appointment-history">
                        <h3>Appointment History</h3>
                        {appointmentHistory.map((appointment, index) => (
                            <div key={index} className="appointment-box">
                                <p>Date: {appointment.date}</p>
                                <p>Notes: {appointment.notes}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PatientPage;
