import React from 'react';
import './Tabs.css';

const Tabs = ({ tabs, currentView, setCurrentView }) => {
    return (
      <div className="tabs">
        {tabs.map(tab => (
          <button
            key={tab.id}
            className={`tab ${currentView === tab.id ? 'active' : ''}`}
            onClick={() => setCurrentView(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>
    );
  };

export default Tabs;