import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';
import { Badge } from '../ui/Badge';
import Sidebar from '../ui/Sidebar';
import MainContent from '../ui/MainContent';
import ChatInterface from './ChatInterface';
import ChatSidebar from './ChatSidebar';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../ui/Table"
import { useNavigate } from 'react-router-dom';

const DentalPatients = () => {
  const navigate = useNavigate();
  const [practiceId, setPracticeId] = useState(localStorage.getItem('practiceId') || '');

  // Sample patient data
  const patients = [
    { 
      id: 1, 
      name: "John Doe", 
      age: 35,
      nextAppointment: "2024-09-01",
      lastVisit: "2024-03-15",
      insuranceProvider: "HealthCare Inc.",
      outstandingBalance: 0
    },
    { 
      id: 2, 
      name: "Jane Smith", 
      age: 28,
      nextAppointment: "2024-08-15",
      lastVisit: "2024-02-20",
      insuranceProvider: "DentalCare Plus",
      outstandingBalance: 75.50
    },
    { 
      id: 3, 
      name: "Alice Johnson", 
      age: 42,
      nextAppointment: "N/A",
      lastVisit: "2023-11-30",
      insuranceProvider: "FamilyDental",
      outstandingBalance: 0
    },
    { 
      id: 4, 
      name: "Robert Brown", 
      age: 50,
      nextAppointment: "2024-07-22",
      lastVisit: "2024-01-10",
      insuranceProvider: "Senior Smile",
      outstandingBalance: 120.75
    },
    { 
      id: 5, 
      name: "Emily Davis", 
      age: 31,
      nextAppointment: "2024-08-05",
      lastVisit: "2024-02-28",
      insuranceProvider: "HealthCare Inc.",
      outstandingBalance: 0
    },
    { 
      id: 6, 
      name: "Michael Wilson", 
      age: 45,
      nextAppointment: "2024-09-18",
      lastVisit: "2024-03-22",
      insuranceProvider: "DentalCare Plus",
      outstandingBalance: 50.00
    },
    { 
      id: 7, 
      name: "Sarah Taylor", 
      age: 39,
      nextAppointment: "2024-07-30",
      lastVisit: "2024-01-25",
      insuranceProvider: "FamilyDental",
      outstandingBalance: 0
    },
    { 
      id: 8, 
      name: "David Martinez", 
      age: 55,
      nextAppointment: "N/A",
      lastVisit: "2023-12-05",
      insuranceProvider: "Senior Smile",
      outstandingBalance: 200.25
    },
    { 
      id: 9, 
      name: "Jennifer Anderson", 
      age: 33,
      nextAppointment: "2024-08-12",
      lastVisit: "2024-02-15",
      insuranceProvider: "HealthCare Inc.",
      outstandingBalance: 0
    },
    { 
      id: 10, 
      name: "Christopher Lee", 
      age: 47,
      nextAppointment: "2024-09-07",
      lastVisit: "2024-03-10",
      insuranceProvider: "DentalCare Plus",
      outstandingBalance: 25.00
    }
  ];

  const handlePatientClick = (patientId) => {
    navigate(`/patient/${patientId}`);
  };

  return (
    <div className="grid min-h-screen w-full md:grid-cols-[147px_1fr] lg:grid-cols-[187px_1fr]">
      <div className="hidden border-r bg-muted/40 md:block">
        <Sidebar practiceId={practiceId}/>
      </div>
      
      <div className="flex flex-col min-h-screen">
        <MainContent title="Patients">
          <Table>
            <TableCaption>A list of your patients</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Name</TableHead>
                <TableHead>Next Appointment</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {patients.map((patient) => (
                <TableRow 
                  key={patient.id} 
                  onClick={() => handlePatientClick(patient.id)}
                  className="cursor-pointer hover:bg-muted/50"
                >
                  <TableCell>{patient.name}</TableCell>
                  <TableCell>{patient.nextAppointment}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </MainContent>
      </div>
    </div>
  );
};

export default DentalPatients;