import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';
import MainContainer from '../../components/MainContainer';
import { getConfirmationsByPractice } from '../../services/patientsService';
import Table from '../../components/Table';
import PatientsToolbar from '../../components/PatientsToolbar';
import { useNavigate } from 'react-router-dom'; 
import DemoBanner from '../../components/DemoBanner';
import { NotificationContext } from '../../NotificationContext';

const ConfirmationsPage = () => {

    const [confirmations, setConfirmations] = useState([]);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { isDemoRunning } = useContext(NotificationContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    
    const [columns] = useState([
        { header: 'Name', key: 'PatientName' },
        { header: 'Appointment Time', key: 'Time' },
        { header: 'Op', key: 'Column' },
        { header: 'Type', key: 'appointmentType' },
        { header: 'Confirmation Status', key: 'PatientStatus' },
    ]);

    const mapStatusToText = (status) => {
        switch (status) {
            case 99:
                return "Second message sent";
            case 9:
                return "First message sent";
            case 2:
                return "Confirmed";
            case 8:
                return "Declined";
            default:
                return "n/a";
        }
    }

    useEffect(() => {
        
        var practiceId = "";
        if (localStorage.getItem('practiceId')) {
            practiceId = localStorage.getItem('practiceId');
            console.log(practiceId);
            // Use practiceId here
        } else {
            console.log('No practiceId found in local storage');
            navigate('/'); 
        }

        getConfirmationsByPractice(practiceId, currentPage, 10, searchTerm)
            .then(data => {
                const modifiedConfirmations = data.confirmations.map(confirmation => ({
                    ...confirmation,
                    url: `/conversation/${confirmation.PatientId}`, 
                    appointmentType: "Cleaning",
                    PatientStatus: mapStatusToText(confirmation.PatientStatus)
                }));
                
                console.log("modifiedConfirmations ", modifiedConfirmations)
                setConfirmations(modifiedConfirmations); // Update this line to setConfirmations
            })
            .catch(error => setError(error));
    
    }, [navigate, currentPage, searchTerm]); 


    if (error) {
        return <div>Error: {error.message}</div>;
    }
    return (
        <>
            {isDemoRunning && <DemoBanner />} 
            <Header/>
            <div className="flex-container">
                <Sidebar />
                <MainContainer title="Patients">
                    <div className="patients-container">
                        <PatientsToolbar/>
                        <input
                            type="text"
                            placeholder="Search Confirmations..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ margin: '20px 0', padding: '10px' }}
                        />

                    <Table data={confirmations} columns={columns} />

                    </div>
                </MainContainer>
            </div>
            <div className="pagination-buttons" style={{ position: 'fixed', bottom: '20px', left: '50%', transform: 'translateX(-50%)', zIndex: '1000' }}>
                <button 
                    onClick={() => setCurrentPage(currentPage - 1)} 
                    disabled={currentPage === 1}
                    style={{ marginRight: '10px' }}
                >
                    Previous Page
                </button>
                <button onClick={() => setCurrentPage(currentPage + 1)}>
                    Next Page
                </button>
            </div>

        </>
    );
};

export default ConfirmationsPage;
