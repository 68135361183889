import React, { createContext, useState, useEffect, useCallback  } from 'react';
import { getNotifCount } from './services/NotificationCountService'; // adjust the path as needed
import { getLockStatus } from './services/RunDemoService';

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notificationCount, setNotificationCount] = useState(0);
    const practiceId = localStorage.getItem('practiceId') || '';
    const [isDemoRunning, setIsDemoRunning] = useState(false);

    const refreshNotifications = useCallback(async () => {
        if (!practiceId) {
            return;
        }
    
        try {

            const count = await getNotifCount(practiceId);
            setNotificationCount(count);
        } catch (error) {
            console.error('Error fetching notifications:', error);
        }
    }, [practiceId]);

    const refreshDemoStatus = useCallback(async () => {
        try {
            const status = await getLockStatus(practiceId);
            setIsDemoRunning(status);
        } catch (error) {
            console.error('Error fetching demo status:', error);
        }
    }, [practiceId]);
    
    useEffect(() => {
        refreshNotifications();
        const notifInterval = setInterval(refreshNotifications, 10000);

        refreshDemoStatus();
        const demoInterval = setInterval(refreshDemoStatus, 15000);

        return () => {
            clearInterval(notifInterval);
            clearInterval(demoInterval); // Clear both intervals on unmount
        };
    }, [refreshNotifications, refreshDemoStatus]);

    return (
        <NotificationContext.Provider value={{ notificationCount, refreshNotifications, isDemoRunning  }}>
            {children}
        </NotificationContext.Provider>
    );
};
