import React, { useEffect, useState } from "react";
import { RetellWebClient } from "retell-client-js-sdk";
import { useNavigate } from 'react-router-dom'; 
import './RunVoiceDemo.css'; 
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar';

const agentId = "cc6f389aa2bdd74676189ef2909fd2c1";

const webClient = new RetellWebClient();

const VoiceDemoPage = () => {
  const [isCalling, setIsCalling] = useState(false);
  const [practiceId, setPracticeId] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();

  // Initialize the SDK
  useEffect(() => {

    var practiceId = ""
    if (localStorage.getItem('practiceId')) {
        practiceId = localStorage.getItem('practiceId');
        setPracticeId(practiceId);
        console.log(practiceId);
        // Use practiceId here
    } else {
        console.log('No practiceId found in local storage');
        navigate('/'); 
    }
    // Setup event listeners
    webClient.on("conversationStarted", () => {
      console.log("conversationStarted");
    });

    webClient.on("audio", (audio) => {
      console.log("There is audio");
    });

    webClient.on("conversationEnded", ({ code, reason }) => {
      console.log("Closed with code:", code, ", reason:", reason);
      setIsCalling(false); // Update button to "Start" when conversation ends
    });

    webClient.on("error", (error) => {
      console.error("An error occurred:", error);
      setIsCalling(false); // Update button to "Start" in case of error
    });

    webClient.on("update", (update) => {
      // Print live transcript as needed
      console.log("update", update);
    });
  }, [navigate]);

  const toggleConversation = async () => {
    if (isCalling) {
      webClient.stopConversation();
    } else {
      const registerCallResponse = await registerCall(agentId);
      if (registerCallResponse.callId) {
        webClient
          .startConversation({
            callId: registerCallResponse.callId,
            sampleRate: registerCallResponse.sampleRate,
            enableUpdate: true,
          })
          .catch(console.error);
        setIsCalling(true); // Update button to "Stop" when conversation starts
      }
    }
  };

  const handleNameChange = async (event) => {
    console.log("handleNameChange");
    const newName = event.target.value;
    setName(newName);
    //await new Promise(resolve => setTimeout(resolve, 2000));
    try {
      const response = await fetch('https://voiceserver.azurewebsites.net/update-name', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name: newName, practiceId: practiceId }),
      });
  
      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
  
      const data = await response.json();
      console.log(data.message);
    } catch (err) {
      console.log(err);
      // Handle the error as needed
    }
  };

  async function registerCall(agentId) {
    try {
    await new Promise(resolve => setTimeout(resolve, 2000));
      // Replace with your server url
      const response = await fetch(
        "https://voiceserver.azurewebsites.net/register-call-on-your-server",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ agentId: agentId, practiceId: practiceId }),
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }

      const data = await response.json();
      return data;
    } catch (err) {
      console.log(err);
      throw new Error(err);
    }
  }

  return (
    <>
      <Header />
      <div className='flex-container'>
        <Sidebar />
        <div className="voice-demo-container">
          <h1>Welcome to the voice demo</h1>
          <p>Enter your name and then click the button to begin the call in the browser</p>
          <div className="name-input">
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              id="name"
              placeholder="Enter your name"
              value={name}
              onChange={handleNameChange}
            />
          </div>
          <button className="start-button" onClick={toggleConversation}>
            {isCalling ? 'Stop' : 'Start'}
          </button>
        </div>
      </div>
    </>
  );
};

export default VoiceDemoPage;